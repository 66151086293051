import { useState } from 'react';

import { Account } from 'models';

interface Data {
  account?: Account;
  baseUrl: string;
}

export const useAccountProvider = () => {
  const [data, setData] = useState<Data>({ baseUrl: '/accounts' });

  const setBaseUrl = (url: string) => {
    setData({ ...data, baseUrl: url });
  };

  return { setBaseUrl, ...data };
};
