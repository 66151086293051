import { fetch } from 'lib';
import { ID } from 'models';

interface Variables {
  fields?: string[];
  routeId?: ID | null;
}

export const getRouteInfo = ({ fields, routeId }: Variables) => {
  if (!routeId) {
    return Promise.resolve();
  }

  return fetch.call(
    'GET',
    '/monitor/shipments/' + routeId,
    {},
    {
      params: {
        fields: !!fields?.length ? JSON.stringify(fields) : null,
      },
    },
  );
};
