import { fetch } from '../../lib';
import { AccountLocation } from '../../models';

interface Variables {
  data: AccountLocation;
  meta: { baseUrl: string };
}

export const addAccountLocation = ({ data, meta }: Variables) => {
  const { account, city, ..._rest } = data;
  const { country, state, ...rest } = _rest;

  return fetch.call('POST', `${meta.baseUrl}/locations`, {
    ...rest,
    accountId: account.id,
    cityId: city.value,
  });
};
