import List from './List';
import PageForm from './PageForm';
import PageDetail from './PageDetail';

const Shipment = {
  List,
  PageDetail,
  PageForm,
};

export default Shipment;
