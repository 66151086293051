import React from 'react';

import { Card, Form } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import * as api from 'api';
import { FormWrapper } from 'components';
import { useAccountUrl } from 'hooks';
import { AccountLocation } from 'models';

import PageForm from './PageForm';

interface Props {
  onCancel?: () => void;
  onSuccess?: () => void;
}

const EditWrapper: React.FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;

  const { accountId, baseApiUrl } = useAccountUrl();
  const { accountLocationId } = useParams<{ accountLocationId: string }>();

  const [form] = Form.useForm();
  const { data: accountLocation, isLoading } = useQuery(
    `getAccountLocation__${accountLocationId}`,
    () =>
      api.getAccountLocation({
        meta: { baseUrl: baseApiUrl },
        params: { accountLocationId: accountLocationId },
      }),
  );
  const { mutateAsync } = useMutation(api.updateAccountLocation);

  const handleFinish = async (values: Record<string, any>) => {
    if (!accountLocation?.id) {
      return;
    }

    const data = {
      ...values,
      account: { id: accountId },
    } as AccountLocation;
    (await mutateAsync({
      data: data,
      meta: { baseUrl: baseApiUrl },
      params: {
        accountLocationId: accountLocation.id,
      },
    })) && onSuccess?.();
  };

  return (
    <Card bodyStyle={{ margin: 'auto', width: '80%' }} loading={isLoading}>
      <FormWrapper
        form={form}
        initialValues={{
          ...accountLocation,
          city: {
            label: accountLocation?.city?.name,
            value: accountLocation?.city?.id,
          },
          country: {
            label: accountLocation?.city?.state?.country?.name,
            value: accountLocation?.city?.state?.country?.id,
          },
          state: {
            label: accountLocation?.city?.state?.name,
            value: accountLocation?.city?.state?.id,
          },
        }}
        layout="vertical"
        onFinish={handleFinish}
        onCancel={() => onCancel?.()}
      >
        <PageForm
          form={form}
          initialAddress={{
            city: !!accountLocation?.city?.id
              ? {
                  id: accountLocation.city.id,
                  label: accountLocation.city.name!,
                  value: accountLocation.city.id,
                }
              : undefined,
            country: !!accountLocation?.city?.state?.country?.id
              ? {
                  id: accountLocation?.city.state.country.id,
                  label: accountLocation?.city.state.country.name,
                  value: accountLocation?.city.state.country.id,
                }
              : undefined,
            state: !!accountLocation?.city?.state?.id
              ? {
                  id: accountLocation.city.state.id,
                  label: accountLocation.city.state.name,
                  value: accountLocation.city.state.id,
                }
              : undefined,
          }}
          title="Editar ubicación"
        />
      </FormWrapper>
    </Card>
  );
};

export default EditWrapper;
