import React, { useEffect, useState } from 'react';

import { Col, Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';

import { SelectSearch } from 'components';
import { City, Country, LabelInValue, State } from 'models';

interface Props {
  form: FormInstance;
  namePathKey: string;
}

const CascadeOnly: React.FC<Props> = (props) => {
  const { form, namePathKey } = props;

  const [initialValues, setInitialValues] = useState<{
    city: LabelInValue<City> | undefined;
    county: LabelInValue<Country> | undefined;
    state: LabelInValue<State> | undefined;
  }>({
    city: undefined,
    county: undefined,
    state: undefined,
  });

  const [cities, setCities] = useState<LabelInValue<City>[]>([]);
  const [countries, setCountries] = useState<LabelInValue<Country>[]>([]);
  const [states, setStates] = useState<LabelInValue<State>[]>([]);

  useEffect(() => {
    const fields = form.getFieldsValue()?.[namePathKey];
    if (!!fields.city && !!fields.country && !!fields.state) {
      setInitialValues({
        city: fields.city,
        county: fields.country,
        state: undefined,
      });
      setCities([fields.city as LabelInValue<City>]);
      setCountries([fields.country as LabelInValue<Country>]);
      setStates([fields.state as LabelInValue<State>]);
    }
  }, [form, namePathKey]);

  return (
    <React.Fragment>
      <Col span={6}>
        <SelectSearch<LabelInValue<Country>>
          formItemProps={{
            name: [namePathKey, 'country'],
            label: 'País',
            rules: [{ required: true }],
          }}
          initialData={countries}
          queryKey={`SELECT_SEARCH__${namePathKey}-country`}
          renderOption={(option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name || option.label}
            </Select.Option>
          )}
          selectProps={{
            labelInValue: true,
            onChange: () => {
              form.setFieldsValue({
                [namePathKey]: { city: undefined, state: undefined },
              });
            },
          }}
          url="/catalogs/countries"
        />
      </Col>

      <Col span={6}>
        <Form.Item
          noStyle={true}
          shouldUpdate={(prev, curr) =>
            prev[namePathKey]?.country?.value !==
            curr[namePathKey]?.country?.value
          }
        >
          {({ getFieldValue }) => {
            const { value: country } =
              getFieldValue([namePathKey, 'country']) || {};
            const hasCountry = !!country;

            return (
              <SelectSearch<LabelInValue<State>>
                formItemProps={{
                  name: [namePathKey, 'state'],
                  label: 'Estado',
                  rules: [{ required: true }],
                }}
                initialData={initialValues?.city?.id !== country ? [] : states}
                queryKey={`SELECT_SEARCH__${namePathKey}-state`}
                queryOptions={{ enabled: hasCountry }}
                queryParams={{ countryId: country }}
                renderOption={(option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name || option.label}
                  </Select.Option>
                )}
                selectProps={{
                  disabled: !hasCountry,
                  labelInValue: true,
                  onChange: () => {
                    form.setFieldsValue({ [namePathKey]: { city: undefined } });
                  },
                }}
                url="/catalogs/states"
              />
            );
          }}
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item
          noStyle={true}
          shouldUpdate={(prev, curr) =>
            prev[namePathKey]?.state?.value !== curr[namePathKey]?.state?.value
          }
        >
          {({ getFieldValue }) => {
            const { value: country } =
              getFieldValue([namePathKey, 'country']) || {};
            const { value: state } =
              getFieldValue([namePathKey, 'state']) || {};
            const hasState = !!state;

            return (
              <SelectSearch<LabelInValue<City>>
                formItemProps={{
                  name: [namePathKey, 'city'],
                  label: 'Ciudad',
                  rules: [{ required: true }],
                }}
                initialData={initialValues?.city?.id !== country ? [] : cities}
                queryKey={`SELECT_SEARCH__${namePathKey}-city`}
                queryOptions={{ enabled: hasState }}
                queryParams={{ stateId: state }}
                renderOption={(option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name || option.label}
                  </Select.Option>
                )}
                selectProps={{
                  disabled: !hasState,
                  labelInValue: true,
                }}
                url="/catalogs/cities"
              />
            );
          }}
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

export default CascadeOnly;
