import React, { useEffect } from 'react';

import { Button, Col, Row, Table } from 'antd';
import { ColumnsType, TableProps as AntTableProps } from 'antd/es/table';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { fetch } from 'lib';

import './TableList.css';

interface Props<T> {
  columns: ColumnsType<T>;
  historyPush: {
    state: any;
    url: string;
  };
  queryParams?: Record<string, any>;
  tableProps?: AntTableProps<T>;
  url: string;
}

export default function TableList<T extends object>(props: Props<T>) {
  const { columns, historyPush, queryParams = {}, tableProps, url } = props;

  const history = useHistory();
  const { data, isFetching, isLoading, refetch } = useQuery(url, async () =>
    fetch.call(
      'GET',
      url,
      {},
      { params: { page: 1, pageSize: 5, ...queryParams } },
    ),
  );

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      refetch().then(() => null);
    }
  }, [queryParams, refetch]);

  return (
    <Row style={{ marginBottom: '10px', marginTop: '10px' }}>
      <Col span={24}>
        <Table<T>
          bordered={false}
          className="table-list"
          columns={columns}
          dataSource={data?.results || []}
          loading={isLoading || isFetching}
          pagination={false}
          footer={() => (
            <Button
              block={true}
              onClick={() => history.push(historyPush.url, historyPush.state)}
            >
              Ver todos
            </Button>
          )}
          rowKey="id"
          size="small"
          scroll={{ x: 990 }}
          {...tableProps}
        />
      </Col>
    </Row>
  );
}
