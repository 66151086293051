import React, { lazy } from 'react';

import { DashboardOutlined } from '@ant-design/icons';

import { Icons } from 'components';
import { AccountUser, MenuRoute, RouterRoute } from 'models';

interface Config {
  getMenus: (user: AccountUser) => MenuRoute[];
  getRoutes: (user: AccountUser) => RouterRoute[];
}

export const getConfig = (accountUser: AccountUser | null | undefined) => {
  if (!accountUser) {
    return { menus: [], routes: [] };
  }

  let config: Config = {
    getMenus: (user: AccountUser) => [
      {
        icon: <DashboardOutlined />,
        path: '/dashboard',
        title: 'Dashboard',
      },
      {
        icon: <Icons.MapRoute />,
        path: '/shipments',
        subMenus: [
          {
            path: '/shipments/waiver',
            title: 'Waiver',
          },
          {
            path: '/shipments',
            // title: 'Normal',
            title: 'Reclamados',
          },
        ],
        title: 'Fletes',
      },
      {
        icon: <Icons.MapPin />,
        path: '/monitoring',
        title: 'Monitoreo',
      },
      {
        icon: <Icons.AccountPaper />,
        path: '/accounts',
        subMenus: [
          {
            path: '/accounts/clients',
            title: 'Clientes',
          },
          {
            path: '/accounts/carriers',
            title: 'Transportistas',
          },
        ],
        title: 'Cuentas',
      },
      {
        icon: <Icons.Settings />,
        path: '/settings',
        subMenus: [
          // {
          //   path: '/settings/contacts',
          //   title: 'Contactos',
          // },
          // {
          //   path: '/settings/information',
          //   title: 'Información general',
          // },
          // {
          //   path: '/settings/users',
          //   title: 'Usuarios',
          // },
          {
            path: '/settings/locations',
            title: 'Ubicaciones',
          },
        ],
        title: 'Configuración',
      },
      // {
      //   // icon: <BookOutlined />,
      //   path: '/catalogs',
      //   title: 'Catálogos',
      // },
    ],

    getRoutes: (user: AccountUser) => [
      // =======================================================================
      // =========================== Account Carrier ===========================
      // =======================================================================

      // Accounts Carrier Config Edit
      {
        component: lazy(() => import('pages/CarrierConfig/Edit')),
        path: '/accounts/carriers/:accountId/configs/:carrierConfigId/edit',
      },

      // Accounts Carrier Config Add
      {
        component: lazy(() => import('pages/CarrierConfig/Add')),
        path: '/accounts/carriers/:accountId/configs/add',
      },

      // Accounts Carrier Contact Edit
      {
        component: lazy(() => import('pages/AccountContact/Edit')),
        path: '/accounts/carriers/:accountId/contacts/:accountContactId/edit',
      },

      // Accounts Carrier Contact Add
      {
        component: lazy(() => import('pages/AccountContact/Add')),
        path: '/accounts/carriers/:accountId/contacts/add',
      },

      // Accounts Carrier Driver Edit
      {
        component: lazy(() => import('pages/Driver/Edit')),
        path: '/accounts/carriers/:accountId/drivers/:driverId/edit',
      },

      // Accounts Carrier Driver Add
      {
        component: lazy(() => import('pages/Driver/Add')),
        path: '/accounts/carriers/:accountId/drivers/add',
      },

      // Accounts Carrier Location Edit
      {
        component: lazy(() => import('pages/AccountLocation/Edit')),
        path: '/accounts/carriers/:accountId/locations/:accountLocationId/edit',
      },

      // Accounts Carrier Location Add
      {
        component: lazy(() => import('pages/AccountLocation/Add')),
        path: '/accounts/carriers/:accountId/locations/add',
      },

      // Accounts Carrier Route Detail
      {
        component: lazy(() => import('pages/Route/Detail')),
        path: '/accounts/carriers/:accountId/routes/:routeId/detail',
      },

      // Accounts Carrier Truck Edit
      {
        component: lazy(() => import('pages/Truck/Edit')),
        path: '/accounts/carriers/:accountId/trucks/:truckId/edit',
      },

      // Accounts Carrier Truck Add
      {
        component: lazy(() => import('pages/Truck/Add')),
        path: '/accounts/carriers/:accountId/trucks/add',
      },

      // Accounts Carrier Shipment Note Edit
      {
        component: lazy(() => import('pages/ShipmentNotes/Edit')),
        path:
          '/accounts/carriers/:accountId/shipment-notes' +
          '/:shipmentNoteId/edit',
      },

      // Accounts Carrier ShipmentNote Add
      {
        component: lazy(() => import('pages/ShipmentNotes/Add')),
        path: '/accounts/carriers/:accountId/shipment-notes/add',
      },

      // Accounts Carrier Config List
      {
        component: lazy(() => import('pages/CarrierConfig/List')),
        path: '/accounts/carriers/:accountId/configs',
      },

      // Accounts Carrier Contact List
      {
        component: lazy(() => import('pages/AccountContact/List')),
        path: '/accounts/carriers/:accountId/contacts',
      },

      // Accounts Carrier Dashboard
      {
        breadcrumb: 'Dashboard',
        component: lazy(() => import('pages/Carrier/Dashboard')),
        path: '/accounts/carriers/:accountId/dashboard',
      },

      // Accounts Carrier Driver List
      {
        component: lazy(() => import('pages/Driver/List')),
        path: '/accounts/carriers/:accountId/drivers',
      },

      // Accounts Carrier Edit
      {
        component: lazy(() => import('pages/Carrier/Edit')),
        path: '/accounts/carriers/:accountId/edit',
      },

      // Accounts Carrier Location List
      {
        component: lazy(() => import('pages/AccountLocation/List')),
        path: '/accounts/carriers/:accountId/locations',
      },

      // Accounts Carrier Route List
      {
        component: lazy(() => import('pages/Route/List')),
        path: '/accounts/carriers/:accountId/routes',
      },

      // Accounts Carrier Shipment Note List
      {
        component: lazy(() => import('pages/ShipmentNotes/List')),
        path: '/accounts/carriers/:accountId/shipment-notes',
      },

      // Accounts Carrier Truck List
      {
        component: lazy(() => import('pages/Truck/List')),
        path: '/accounts/carriers/:accountId/trucks',
      },

      // Accounts Carrier Add
      {
        component: lazy(() => import('pages/Carrier/Add')),
        path: '/accounts/carriers/add',
      },

      // Accounts Carrier List
      {
        component: lazy(() => import('pages/Carrier/List')),
        path: '/accounts/carriers',
      },

      // =======================================================================
      // =========================== Account Client ============================
      // =======================================================================

      // Accounts Client Contact Edit
      {
        component: lazy(() => import('pages/AccountContact/Edit')),
        path: '/accounts/clients/:accountId/contacts/:accountContactId/edit',
      },

      // Accounts Client Contact Add
      {
        component: lazy(() => import('pages/AccountContact/Add')),
        path: '/accounts/clients/:accountId/contacts/add',
      },

      // Accounts Client Route Detail
      {
        component: lazy(() => import('pages/Route/Detail')),
        path: '/accounts/clients/:accountId/routes/:routeId/detail',
      },

      // Accounts Client Location Edit
      {
        component: lazy(() => import('pages/AccountLocation/Edit')),
        path: '/accounts/clients/:accountId/locations/:accountLocationId/edit',
      },

      // Accounts Client Location Add
      {
        component: lazy(() => import('pages/AccountLocation/Add')),
        path: '/accounts/clients/:accountId/locations/add',
      },

      // Accounts Client Shipment Note Edit
      {
        component: lazy(() => import('pages/ShipmentNotes/Edit')),
        path:
          '/accounts/clients/:accountId/shipment-notes' +
          '/:shipmentNoteId/edit',
      },

      // Accounts Client ShipmentNote Add
      {
        component: lazy(() => import('pages/ShipmentNotes/Add')),
        path: '/accounts/clients/:accountId/shipment-notes/add',
      },

      // Accounts Client Contact List
      {
        component: lazy(() => import('pages/AccountContact/List')),
        path: '/accounts/clients/:accountId/contacts',
      },

      // Accounts Client Dashboard
      {
        component: lazy(() => import('pages/Client/Dashboard')),
        path: '/accounts/clients/:id/dashboard',
      },

      // Accounts Client Edit
      {
        component: lazy(() => import('pages/Client/Edit')),
        path: '/accounts/clients/:id/edit',
      },

      // Accounts Client Location List
      {
        component: lazy(() => import('pages/AccountLocation/List')),
        path: '/accounts/clients/:accountId/locations',
      },

      // Accounts Client Route List
      {
        component: lazy(() => import('pages/Route/List')),
        path: '/accounts/clients/:accountId/routes',
      },

      // Accounts Client Shipment Note List
      {
        component: lazy(() => import('pages/ShipmentNotes/List')),
        path: '/accounts/clients/:accountId/shipment-notes',
      },

      // Accounts Client Add
      {
        component: lazy(() => import('pages/Client/Add')),
        path: '/accounts/clients/add',
      },

      // Accounts Client List
      {
        component: lazy(() => import('pages/Client/List')),
        path: '/accounts/clients',
      },

      // =======================================================================
      // ============================ Dashboard ================================
      // =======================================================================

      // Dashboard
      {
        component: lazy(() => import('pages/Dashboard')),
        path: '/dashboard',
      },

      // =======================================================================
      // ============================ Monitoring ===============================
      // =======================================================================

      // Monitoring
      {
        component: lazy(() => import('pages/Monitoring')),
        path: '/monitoring',
      },

      // =======================================================================
      // ========================= Account Settings ============================
      // =======================================================================

      // // Setting AccountInformation
      // {
      //   component: lazy(() => import('pages/Settings/AccountInformation')),
      //   path: '/settings/information',
      // },

      // // Settings Contact Edit
      // {
      //   component: lazy(() => import('pages/AccountContact/Edit')),
      //   path: '/settings/contacts/:accountContactId/edit',
      // },

      // // Settings Contact Add
      // {
      //   component: lazy(() => import('pages/AccountContact/Add')),
      //   path: '/settings/contacts/add',
      // },

      // // Settings Contact List
      // {
      //   component: lazy(() => import('pages/AccountContact/List')),
      //   path: '/settings/contacts',
      // },

      // Settings Location Edit
      {
        component: lazy(() => import('pages/AccountLocation/Edit')),
        path: '/settings/locations/:accountLocationId/edit',
      },

      // Settings Location Add
      {
        component: lazy(() => import('pages/AccountLocation/Add')),
        path: '/settings/locations/add',
      },

      // Settings Location List
      {
        component: lazy(() => import('pages/AccountLocation/List')),
        path: '/settings/locations',
      },

      // =======================================================================
      // ============================= Shipments ===============================
      // =======================================================================

      // Shipments Detail
      {
        component: lazy(() => import('pages/Shipment/Detail')),
        path: '/shipments/:shipmentId/detail',
      },

      // Shipments Add
      {
        component: lazy(() => import('pages/Shipment/Add')),
        path: '/shipments/add',
      },

      // Shipments Waiver Add
      {
        component: lazy(() => import('pages/Shipment/Add')),
        path: '/shipments/waiver/add',
      },

      // Shipments Waiver List
      {
        component: lazy(() => import('pages/Shipment/List')),
        path: '/shipments/waiver',
      },

      // Shipments List
      {
        component: lazy(() => import('pages/Shipment/List')),
        path: '/shipments',
      },
    ],
  };

  return {
    menus: config.getMenus(accountUser),
    routes: config.getRoutes(accountUser),
  };
};
