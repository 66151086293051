import React, { createContext } from 'react';

import { useAuthProvider } from '../../hooks';
import { AccountUser } from '../../models';

interface IAuthContext {
  loading: boolean;
  login: (
    credentials: { email: string; password: string },
    cb: () => void,
  ) => Promise<void>;
  loginByToken: () => Promise<void>;
  logout: (cb: () => void) => void;
  user: AccountUser | null | undefined;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC = (props) => {
  const auth = useAuthProvider();

  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
};
