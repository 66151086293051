import React from 'react';

import './BadgeText.css';

interface Props {
  color: string;
  style?: React.CSSProperties;
  text: React.ReactNode;
}

const BadgeText: React.FC<Props> = (props) => (
  <span
    className="badge-text"
    style={{
      ...props.style,
      backgroundColor: props.color,
    }}
  >
    {props.text}
  </span>
);

export default BadgeText;
