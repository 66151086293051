import { notification } from 'antd';
import axios, { AxiosRequestConfig, Method } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { anyPass, isEmpty, isNil, reject } from 'ramda';

import { getSession } from './session';

const defaultTransformRequest = Array.isArray(axios.defaults.transformRequest)
  ? axios.defaults.transformRequest
  : !!axios.defaults.transformRequest
  ? [axios.defaults.transformRequest]
  : [];

const defaultTransformResponse = Array.isArray(axios.defaults.transformResponse)
  ? axios.defaults.transformResponse
  : !!axios.defaults.transformResponse
  ? [axios.defaults.transformResponse]
  : [];

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'es-mx',
  },

  transformRequest: [
    (data, headers) => {
      const access = getSession('access');
      if (access) {
        headers.Authorization = `Bearer ${access}`;
      }
      return data;
    },
    ...defaultTransformRequest,
  ],

  transformResponse: [
    ...defaultTransformResponse,
    (data) => {
      return camelcaseKeys(data, { deep: true });
    },
  ],
});

export async function call<TData = any>(
  method: Method,
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
) {
  const requestParams = {
    data: data,
    method: method,
    url: url,
    ...config,
    params: reject(anyPass([isEmpty, isNil]), config?.params || {}),
  };

  try {
    const response = await axiosInstance.request<TData>(requestParams);
    return response.data;
  } catch (error) {
    notification.error({
      description: error.response?.data?.message,
      message: 'Error',
    });
    return Promise.reject();
  }
}
