import { fetch } from 'lib';
import { ID } from 'models';

interface Variables {
  data: {
    location: {
      address: string;
      lat: number;
      lng: number;
    };
    status: string;
  };
  params: { routeId: ID };
}

export const cancelRoute = ({ data, params }: Variables) =>
  fetch.call(
    'POST',
    `/accounts/routes/${params.routeId}/timeline/manual`,
    data,
  );
