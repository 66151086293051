import React, { useState } from 'react';

import { ApiOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { nanoid } from 'nanoid';
import { all } from 'ramda';

import { Card } from 'components';

import AssigmentModal from './AssigmentModal';

interface Props {
  onNextStep: (data: any[]) => void;
  onPrevStep: () => void;
  routes: any[];
  routeType: 'multiple' | 'single' | 'fast' | undefined;
}

interface ModalState {
  index: null | number;
  route: any | null;
  visible: boolean;
}

const AssigmentStep: React.FC<Props> = (props) => {
  const { onNextStep, onPrevStep, routes, routeType } = props;

  const [modal, setModal] = useState<ModalState>({
    index: null,
    route: null,
    visible: false,
  });
  const [routesCached, setRoutesCached] = useState<any[]>(routes);

  const canGoNext = () => all((route: any) => !!route.assignment)(routesCached);

  const columns: ColumnsType<any> = [
    {
      key: 'route',
      render: (text, record) => (
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <span style={{ flex: 1 }}>{record?.origin?.label}</span>
          <ArrowRightOutlined style={{ flex: 1, fontSize: 24 }} />
          <span style={{ flex: 1 }}>{record?.destination?.label}</span>
        </div>
      ),
      title: 'Ruta',
    },
    {
      dataIndex: ['assignment', 'truck', 'label'],
      key: 'truck',
      title: 'Unidad',
    },
    {
      dataIndex: ['assignment', 'driver', 'label'],
      key: 'driver',
      title: 'Operador',
    },
    {
      align: 'center',
      key: 'edit',
      render: (text, record, index) =>
        !!record.assignment ? (
          <EditOutlined
            onClick={() =>
              setModal({ index: index, route: record, visible: true })
            }
          />
        ) : (
          <ApiOutlined
            onClick={() =>
              setModal({ index: index, route: record, visible: true })
            }
          />
        ),
    },
  ];

  const fastColumns: ColumnsType<any> = [
    {
      key: 'route',
      render: (text, record) => (
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <span style={{ flex: 1 }}>{record?.origin?.address}</span>
          <ArrowRightOutlined style={{ flex: 1, fontSize: 24 }} />
          <span style={{ flex: 1 }}>{record?.destination?.address}</span>
        </div>
      ),
      title: 'Ruta',
    },
    {
      dataIndex: ['assignment', 'truck', 'label'],
      key: 'truck',
      title: 'Unidad',
    },
    {
      dataIndex: ['assignment', 'driver', 'label'],
      key: 'driver',
      title: 'Operador',
    },
    {
      align: 'center',
      key: 'edit',
      render: (text, record, index) =>
        !!record.assignment ? (
          <EditOutlined
            onClick={() =>
              setModal({ index: index, route: record, visible: true })
            }
          />
        ) : (
          <ApiOutlined
            onClick={() =>
              setModal({ index: index, route: record, visible: true })
            }
          />
        ),
    },
  ];

  return (
    <Row gutter={24}>
      <Card.Divider>Asignaciones</Card.Divider>

      <Col span={24}>
        <Table
          bordered={true}
          columns={routeType !== 'fast' ? columns : fastColumns}
          dataSource={routesCached}
          pagination={false}
          size="small"
          rowKey={() => nanoid()}
          style={{ margin: '20px 0' }}
        />
      </Col>

      <Divider />

      <Col span={24} style={{ textAlign: 'right' }}>
        <Space>
          <Button onClick={() => onPrevStep()}>Cancelar</Button>
          <Button
            disabled={!canGoNext()}
            type="primary"
            onClick={() => onNextStep(routesCached)}
          >
            Siguiente
          </Button>
        </Space>
      </Col>

      <AssigmentModal
        onCancel={() => setModal({ index: null, route: null, visible: false })}
        onFinish={(assignment) => {
          if (modal.index !== null && modal.route !== null) {
            const tmpRoutes = [...routesCached];
            tmpRoutes[modal.index] = { ...modal.route, assignment: assignment };
            setRoutesCached(tmpRoutes);
            setModal({ index: null, route: null, visible: false });
          }
        }}
        route={modal.route}
        visible={modal.visible && !!modal.route}
      />
    </Row>
  );
};

export default AssigmentStep;
