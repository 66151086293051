import React from 'react';

import { ProFormText } from '@ant-design/pro-form';
import { Col, Form, Input, Row, Select } from 'antd';

import { Card, SelectQuery } from 'components';
import { useAccountUrl } from 'hooks';
import { AccountJobPosition, AccountLocation } from 'models';

interface Props {
  readonly?: boolean;
  title?: string;
}

const PageForm: React.FC<Props> = (props) => {
  const { readonly = false, title } = props;

  const urls = useAccountUrl();

  return (
    <Row gutter={24}>
      {title && <Card.Title title={title} />}

      <Card.Divider>Información general</Card.Divider>

      <Col span={8}>
        <ProFormText
          name={['firstName']}
          label="Nombre"
          rules={[{ required: true }]}
          readonly={readonly}
        />
      </Col>

      <Col span={8}>
        <Form.Item
          name={['lastName']}
          label="Apellido"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={8} />

      <Col span={8}>
        <Form.Item
          name={['email']}
          label="Email"
          rules={[{ type: 'email' }, { required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={16} />

      <Col span={8}>
        <Form.Item
          name={['phone']}
          label="Teléfono"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name={['phoneMobile']}
          label="Celular"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={8} />

      <Col span={8}>
        <SelectQuery<AccountJobPosition>
          formItemProps={{
            name: ['accountJobPosition', 'id'],
            label: 'Cargo',
            rules: [{ required: true }],
          }}
          renderOption={(option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          )}
          url="/catalogs/account-job-position"
        />
      </Col>

      <Col span={8}>
        <SelectQuery<AccountLocation>
          formItemProps={{
            name: ['accountLocation', 'id'],
            label: 'Base operativa',
            rules: [{ required: true }],
          }}
          renderOption={(option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          )}
          url={`${urls.baseApiUrl}/locations`}
        />
      </Col>
    </Row>
  );
};

export default PageForm;
