import { fetch } from 'lib';
import { ID, ShipmentOperativeStatusValue } from 'models';

interface Variables {
  data: { status: ShipmentOperativeStatusValue };
  params: { routeId: ID };
}

export const updateRouteStatus = ({ data, params }: Variables) =>
  fetch.call(
    'POST',
    `/accounts/routes/${params.routeId}/timeline/manual`,
    data,
  );
