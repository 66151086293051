import React, { useState } from 'react';

import { Col, Row, Steps } from 'antd';

import { Card } from 'components';

import './AddWrapper.css';
import AssigmentStep from './AssigmentStep';
import RouteStep from './RouteStep';
import SummaryStep from './SummaryStep';
import { useHistory } from 'react-router-dom';

const AddWrapper = () => {
  const history = useHistory();

  const [current, setCurrent] = useState(0);
  const [routes, setRoutes] = useState<any[] | undefined>(undefined);
  const [routeType, setRouteType] = useState<
    'multiple' | 'single' | 'fast' | undefined
  >(undefined);
  const isWaiver = history.location.pathname.includes('waiver');

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <Card>
      <Card.Title
        subTitle="Bienvenido al creador de fletes, agregue
          todos los datos requeridos para continuar."
        title="Creación de fletes"
      />
      <Row className="add-shipment__steps-container" gutter={24}>
        <Col span={5}>
          <Steps current={current} direction="vertical">
            <Steps.Step key="routes" title="Agregar viaje" />
            {routeType !== 'fast' && !isWaiver && (
              <Steps.Step key="assigment" title="Asignaciones" />
            )}
            <Steps.Step key="summary" title="Resumen" />
          </Steps>
        </Col>

        <Col span={19}>
          {current === 0 && (
            <RouteStep
              onNextStep={(_routes) => {
                setRoutes(_routes);
                next();
              }}
              routes={routes}
              routeType={routes === undefined ? undefined : routeType}
              callback={(value: 'multiple' | 'single' | 'fast' | undefined) =>
                setRouteType(value)
              }
              isWaiver={isWaiver}
            />
          )}

          {current === 1 &&
            !!routes?.length &&
            routeType !== 'fast' &&
            !isWaiver && (
              <AssigmentStep
                onNextStep={(_routes) => {
                  setRoutes(_routes);
                  next();
                }}
                onPrevStep={() => prev()}
                routes={routes}
                routeType={routeType}
              />
            )}

          {(current === 2 ||
            (current === 1 && (routeType === 'fast' || isWaiver))) &&
            !!routes?.length && (
              <SummaryStep
                onPrevStep={() => prev()}
                routes={routes}
                routeType={routeType}
                isWaiver={isWaiver}
              />
            )}
        </Col>
      </Row>
    </Card>
  );
};

export default AddWrapper;
