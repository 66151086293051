import React from 'react';

import { nanoid } from 'nanoid';

import { Typography } from 'antd';

import './Title.css';

interface Props {
  subTitles?: React.ReactNode[];
  title: React.ReactNode;
}

export const Title: React.FC<Props> = (props) => {
  const { subTitles, title } = props;
  return (
    <div className="title__content">
      <Typography.Title className="title__title" level={3}>
        {title}
      </Typography.Title>
      {subTitles?.map((st) => (
        <Typography.Paragraph
          className="title__sub-title"
          key={nanoid()}
          type="secondary"
        >
          {st}
        </Typography.Paragraph>
      ))}
    </div>
  );
};
