import React, { useState } from 'react';

import { Card, Layout, Steps } from 'antd';

import './AccountRegister.css';

import { RegisterStep } from './RegisterStep';
import { VerifyStep } from './VerifyStep';

import { Layouts } from '../../components';
import { FinishStep } from './FinishStep';

export const AccountRegister = () => {
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState<any>({});

  return (
    <Layouts.PublicLayout>
      <Layout className="account-register__layout">
        <Card className="account-register__card">
          <Steps
            className="account-register__steps"
            current={current}
            progressDot={true}
          >
            <Steps.Step description="REGISTRO" />
            <Steps.Step description="VERIFICAR" />
            <Steps.Step description="FINALIZAR" />
          </Steps>

          {current === 0 && (
            <RegisterStep
              initialValues={formData}
              setStep={(formData, step) => {
                setFormData(formData);
                setCurrent(step);
              }}
            />
          )}

          {current === 1 && (
            <VerifyStep
              initialValues={formData}
              setStep={(step) => setCurrent(step)}
            />
          )}

          {current === 2 && <FinishStep />}
        </Card>
      </Layout>
    </Layouts.PublicLayout>
  );
};
