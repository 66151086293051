import React from 'react';

import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import * as api from 'api';
import { useAccountUrl } from 'hooks';
import { CarrierConfig } from 'models';
import { Card, FormWrapper } from 'components';

import PageForm from './PageForm';

interface Props {
  onCancel?: () => void;
  onSuccess?: () => void;
}

const EditWrapper: React.FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;

  const { accountId } = useAccountUrl();
  const { carrierConfigId } = useParams<{ carrierConfigId: string }>();

  const { data: carrierConfig, isLoading } = useQuery(
    `getCarrierConfig__${carrierConfigId}`,
    () =>
      api.getCarrierConfig({
        params: { accountId: accountId!, carrierConfigId: carrierConfigId },
      }),
  );
  const { mutateAsync } = useMutation(api.updateCarrierConfig);

  const handleFinish = async (values: Record<string, any>) => {
    if (!carrierConfig?.id) {
      return;
    }

    const data = values as CarrierConfig;
    accountId &&
      (await mutateAsync({
        data: data,
        params: {
          accountId: accountId,
          carrierConfigId: carrierConfig.id,
        },
      })) &&
      onSuccess?.();
  };

  return (
    <Card bodyStyle={{ margin: 'auto', width: '80%' }} loading={isLoading}>
      <FormWrapper
        initialValues={carrierConfig}
        layout="vertical"
        onFinish={handleFinish}
        onCancel={() => onCancel?.()}
      >
        <PageForm title="Editar configuración" />
      </FormWrapper>
    </Card>
  );
};

export default EditWrapper;
