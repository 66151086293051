import { fetch } from 'lib';

interface Variables {
  data: { routes: any[] };
}

export const addShipment = ({ data }: Variables) => {
  return fetch.call('POST', 'accounts/shipments/register', {
    routes: data.routes.map((route) => {
      const { assignment, carrier, client, destination, origin } = route;
      const { shipmentModalType, shipmentType } = route;
      const { driver, trailer1, trailer2, truck } = assignment;

      return {
        assignment: {
          accountTruckId: truck.value,
          driverId: driver.value,
          trailer1Id: trailer1?.value,
          trailer2Id: trailer2?.value,
        },
        carrierId: carrier.value,
        clientId: client.value,
        customerOrder: route.customerOrder,
        destinationLocationId: destination.value,
        extra: {
          etao: route.etao,
          etad: route.etad,
          etdo: route.etdo,
          etdd: route.etdd,
        },
        originLocationId: origin.value,
        receivers: route.receivers,
        routeCost: route.routeCost,
        routeSale: route.routeSale,
        shipmentModalTypeValue: shipmentModalType.value,
        shipmentTypeId: shipmentType.value,
        note: route.note,
        goods: route.goods,
        weight: route.weight,
      };
    }),
  });
};
