import React from 'react';

import { ProFormText } from '@ant-design/pro-form';
import { Col, Row } from 'antd';

import { Card } from 'components';

interface Props {
  readonly?: boolean;
  title?: string;
}

const PageForm: React.FC<Props> = (props) => {
  const { readonly = false, title } = props;

  return (
    <Row gutter={24}>
      {title && <Card.Title title={title} />}
      <Card.Divider>Cuenta espejo</Card.Divider>

      <Col span={8}>
        <ProFormText
          label="Pagina web"
          name={['mirrorWebsite']}
          readonly={readonly}
          rules={[{ required: true }]}
        />
      </Col>

      <Col span={8}>
        <ProFormText
          label="Usuario"
          name={['mirrorUser']}
          readonly={readonly}
          rules={[{ required: true }]}
        />
      </Col>

      <Col span={8}>
        <ProFormText
          label="Contraseña"
          name={['mirrorPassword']}
          readonly={readonly}
          rules={[{ required: true }]}
        />
      </Col>
    </Row>
  );
};

export default PageForm;
