import React, { useState } from 'react';

import { GoogleMap, Marker } from '@react-google-maps/api';
import { useDebounceEffect } from 'ahooks';

const containerStyle = {
  height: '300px',
  width: '100%',
};

interface Props {
  address: string;
  initialPosition?: { lat: number; lng: number };
  onChange: (coordinates: google.maps.LatLngLiteral) => void;
}

export const MapLookupLocation: React.FC<Props> = (props) => {
  const { address, onChange } = props;
  const {
    initialPosition = {
      lat: 19.432014,
      lng: -99.133421,
    },
  } = props;

  const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();
  const [map, setMap] = useState<google.maps.Map>();
  const [marker, setMarker] = useState<google.maps.Marker>();

  useDebounceEffect(() => {
    if (!!address && !!geocoder) {
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          const result = results[0];
          map?.setCenter(result.geometry.location);
          marker?.setPosition(result.geometry.location);
        }
      });
    }
  }, [address]);

  const handleDragEnd = (event: google.maps.MapMouseEvent) => {
    onChange(event.latLng.toJSON());
  };

  const handleMapLoad = (map: google.maps.Map) => {
    setMap(map);
    setGeocoder(new google.maps.Geocoder());
  };

  const handleMarkerLoad = (marker: google.maps.Marker) => {
    setMarker(marker);
  };

  const handlePositionChanged = () => {
    const position = marker?.getPosition();
    position && onChange(position.toJSON());
  };

  return (
    <GoogleMap
      center={initialPosition}
      mapContainerStyle={containerStyle}
      onLoad={handleMapLoad}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
      }}
      zoom={8}
    >
      <Marker
        draggable={true}
        position={initialPosition}
        onDragEnd={handleDragEnd}
        onPositionChanged={handlePositionChanged}
        onLoad={handleMarkerLoad}
      />
    </GoogleMap>
  );
};
