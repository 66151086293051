import { fetch } from '../../lib';
import { Account } from '../../models';

interface Variables {
  data: Omit<Account, 'id'>;
  meta: { baseUrl: string };
  params: { accountId: number | string };
}

export const updateAccount = ({ data, meta, params }: Variables) => {
  const { contact, ...rest } = data;
  return fetch.call('PUT', `${meta.baseUrl}/${params.accountId}`, {
    ...contact,
    ...rest,
  });
};
