import React, { useState } from 'react';

import {
  ArrowRightOutlined,
  EditTwoTone,
  FileTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Col, notification, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import * as api from 'api';

import './List.css';
import { Card, DateFormatted, Icons, Table } from 'components';
import { Shipment } from 'models';
import Evidence from './Evidence';
import AssigmentModal from '../PageForm/AddWrapper/AssigmentStep/AssigmentModal';

interface ModalState {
  index: number | null;
  route: any | null;
  visible: boolean;
}

const List = () => {
  const history = useHistory();
  const { isLoading, mutateAsync } = useMutation(api.addEvidence);
  const {
    // isLoading: isLoagingAssignment,
    mutateAsync: mutateAsyncAssignment,
  } = useMutation(api.addAssignment);

  const [evidenceVisibility, setEvidenceVisibility] = useState<
    string | undefined
  >(undefined);
  const [pendingClosure, setPendingClosure] = useState(0);
  const [pendingAsignment, setPendingAssignment] = useState(0);
  const [tableKey, setTableKey] = useState(0);
  const [modal, setModal] = useState<ModalState>({
    index: null,
    route: null,
    visible: false,
  });
  const [expandedRows, setExpandedRows] = useState<Array<React.Key>>([]);
  const locationPath = history.location.pathname;
  const isWaiver = locationPath.includes('waiver');

  const actions = [
    <Button
      icon={<PlusOutlined />}
      key="add"
      onClick={() => history.push(`${locationPath}/add`)}
      // onClick={() => history.push(`/shipments/add`)}
      // onClick={() => history.push('/shipments/waiver/add')}
      type="primary"
    >
      Agregar flete
    </Button>,
  ];

  const columns: ColumnsType<Shipment> = [
    {
      key: 'prevIcons',
      render: (text, record) => {
        const { routes } = record;

        const { assigned, closed } = routes.reduce(
          (acc, curr) => {
            if (acc.assigned) {
              acc.assigned = curr.assignment ? true : false;
            }
            if (acc.closed) {
              const open =
                curr.evidences.length === 0 ||
                curr.signature === null ||
                curr.cartaporte === null;
              acc.closed = !open;
            }

            return acc;
          },
          { closed: true, assigned: true },
        );

        if (assigned || isWaiver) {
          return <div></div>;
        }
        if ((closed && assigned) || isWaiver) {
          return <div></div>;
        }

        return (
          <span>
            {!closed && (
              <>
                <Icons.Alert />
                &nbsp;
              </>
            )}
            {!assigned && <EditTwoTone />}
          </span>
        );
      },
      width: isWaiver ? 0 : 50,
      // width: isWaiver ? 0 : 25,
    },
    {
      key: 'name',
      render: (text, record) => {
        const { routes } = record;

        const { assigned } = routes.reduce(
          (acc, curr) => {
            if (acc.assigned) {
              acc.assigned = curr.assignment ? true : false;
            }
            if (acc.closed) {
              const open =
                curr.evidences.length === 0 ||
                curr.signature === null ||
                curr.cartaporte === null;
              acc.closed = !open;
            }

            return acc;
          },
          { closed: true, assigned: true },
        );

        const bold = !assigned && !isWaiver;

        // const bold = (!closed || !assigned) && !isWaiver;

        return (
          <Link
            to={`/shipments/${record.id}/detail`}
            style={{ fontWeight: bold ? 'bold' : 'normal' }}
          >
            {record.folio}
          </Link>
        );
      },
      title: 'Folio',
      width: 145,
    },
    {
      ellipsis: true,
      key: 'origin',
      render: (text, record) => {
        const { routes } = record;
        const { assigned } = routes.reduce(
          (acc, curr) => {
            if (acc.assigned) {
              acc.assigned = curr.assignment ? true : false;
            }
            if (acc.closed) {
              const open =
                curr.evidences.length === 0 ||
                curr.signature === null ||
                curr.cartaporte === null;
              acc.closed = !open;
            }

            return acc;
          },
          { closed: true, assigned: true },
        );

        const bold = !assigned && !isWaiver;
        // const bold = (!closed || !assigned) && !isWaiver;

        return (
          <span style={{ fontWeight: bold ? 'bold' : 'normal' }}>
            {record.routes[0].originLocation?.address ||
              record.routes[0].extra?.origin?.address}
          </span>
        );
      },
      title: 'Origen',
    },
    {
      key: 'arrow',
      render: (text, record) => (
        <span>
          <ArrowRightOutlined />
        </span>
      ),
      width: 30,
    },
    {
      ellipsis: true,
      key: 'destination',
      render: (text, record) => {
        const { routes } = record;

        const { assigned, closed } = routes.reduce(
          (acc, curr) => {
            if (acc.assigned) {
              acc.assigned = curr.assignment ? true : false;
            }
            if (acc.closed) {
              const open =
                curr.evidences.length === 0 ||
                curr.signature === null ||
                curr.cartaporte === null;
              acc.closed = !open;
            }

            return acc;
          },
          { closed: true, assigned: true },
        );

        // const bold = !assigned && !isWaiver;
        const bold = (!closed || !assigned) && !isWaiver;
        return (
          <span style={{ fontWeight: bold ? 'bold' : 'normal' }}>
            {record.routes[record.routes.length - 1].destinationLocation
              ?.address ||
              record.routes[record.routes.length - 1].extra.destination
                ?.address}
          </span>
        );
      },
      title: 'Destino',
    },
    {
      key: 'eta',
      render: (text, record) => {
        const { routes } = record;

        const { assigned, closed } = routes.reduce(
          (acc, curr) => {
            if (acc.assigned) {
              acc.assigned = curr.assignment ? true : false;
            }
            if (acc.closed) {
              const open =
                curr.evidences.length === 0 ||
                curr.signature === null ||
                curr.cartaporte === null;
              acc.closed = !open;
            }

            return acc;
          },
          { closed: true, assigned: true },
        );
        //Se pone en bold si alguno o isWaiver
        // const bold = !isWaiver && !assigned;
        const bold = !isWaiver && (!closed || !assigned);

        return (
          <DateFormatted datetime={record.routes[0].extra?.etao} bold={bold} />
        );
      },
      title: 'ETA',
      width: 125,
    },
  ];

  return (
    <Card>
      <Row justify="space-between">
        <Col>
          <Card.Title title="Fletes" />
        </Col>
        <Col>
          <Row>
            {pendingClosure > 0 && (
              <Col>
                <p className="shipment_close__text">
                  <span>
                    <Icons.Alert />
                  </span>
                  {pendingClosure === 1
                    ? `1 cierre administrativo pendiente`
                    : `${pendingClosure} cierres administrativos pendientes`}
                </p>
              </Col>
            )}
          </Row>
          <Row>
            {pendingAsignment > 0 && (
              <Col>
                <p className="shipment_assign__text">
                  <span>
                    <EditTwoTone />
                  </span>
                  {pendingAsignment === 1
                    ? `1 asignacion pendiente`
                    : `${pendingAsignment} asignaciones pendientes`}
                </p>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Table<Shipment>
        fetchedData={
          isWaiver
            ? () => {}
            : (data: any) => {
                const as = data?.results.reduce(
                  (acc: any, curr: any) => {
                    const { openRoutes, unassignedRoutes } = curr.routes.reduce(
                      (a: any, c: any) => {
                        const open =
                          c.cartaporte === null ||
                          c.signature === null ||
                          c.evidences.length === 0;
                        if (open) {
                          a.openRoutes = a.openRoutes + 1;
                        }

                        if (!c.assignment) {
                          a.unassignedRoutes = a.unassignedRoutes + 1;
                        }
                        return a;
                      },
                      { openRoutes: 0, unassignedRoutes: 0 },
                    );
                    acc.openTotal = acc.openTotal + openRoutes;
                    acc.unassignedTotal =
                      acc.unassignedTotal + unassignedRoutes;
                    return acc;
                  },
                  { openTotal: 0, unassignedTotal: 0 },
                );

                if (as) {
                  setPendingClosure(as.openTotal);
                  setPendingAssignment(as.unassignedTotal);
                }
              }
        }
        actions={isWaiver ? actions : []}
        columns={columns}
        url={`/accounts/shipments?isWaiver=${isWaiver}`}
        bordered={false}
        tableProps={{
          expandable: {
            onExpand: (expanded, record) => {
              if (expanded) {
                setExpandedRows([...expandedRows, record.id]);
              } else {
                const cexp = expandedRows;
                const index = cexp.indexOf(record.id);
                if (index !== -1) cexp.splice(index, 1);
                setExpandedRows(cexp);
              }
            },
            expandedRowKeys: expandedRows,
            expandedRowRender: (record) => {
              const routes = record.routes.map((route, index) => {
                const open =
                  route.evidences.length === 0 ||
                  route.signature === null ||
                  route.cartaporte === null;

                return (
                  <div key={index} className="shipment-list__routes">
                    {isWaiver ? (
                      <div></div>
                    ) : (
                      // <div>{route.assignment && <Icons.Check />}</div>
                      <div>{!open && route.assignment && <Icons.Check />}</div>
                    )}
                    <div>
                      {index + 1} -{' '}
                      {route.originLocation?.address ||
                        route.extra.origin?.address}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <ArrowRightOutlined />
                    </div>

                    <div>
                      {route.destinationLocation?.address ||
                        route.extra.destination?.address}
                    </div>
                    {!isWaiver && (
                      <div>
                        <FileTwoTone
                          twoToneColor="#EA371C"
                          onClick={() => {
                            setEvidenceVisibility(`${record.id}${index}`);
                          }}
                        />
                      </div>
                    )}
                    {!route.assignment && !isWaiver && (
                      <div>
                        <EditTwoTone
                          onClick={() => {
                            setModal({
                              index: index,
                              route: route,
                              visible: true,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              });

              const evidence = record.routes.map((route, index) => {
                return (
                  <Evidence
                    isLoading={isLoading}
                    onCancel={() => {
                      setEvidenceVisibility(undefined);
                    }}
                    onFinish={async (values) => {
                      mutateAsync({
                        data: {
                          values,
                          config: {
                            headers: {
                              'content-type': 'multipart/form-data',
                            },
                          },
                        },
                      })
                        .then(() => {
                          setEvidenceVisibility(undefined);
                          setTableKey(tableKey + 1);
                        })
                        .catch((e) => {
                          notification.error({
                            message: 'Error al cargar evidencias',
                            description: e,
                          });
                        });
                    }}
                    visible={evidenceVisibility === `${record.id}${index}`}
                    route={route}
                    index={index + 1}
                  />
                );
              });

              return (
                <div style={{ margin: '5px 16px' }}>
                  <p className="shipment_bold__text">{'Tramos'}</p>
                  <p style={{ margin: 0 }}>
                    {!isWaiver &&
                      'Favor de agregar las evidencias que correspondan (fotografias y carta porte) a los tramos indicados a continuación'}
                  </p>
                  <br></br>
                  {routes}
                  {evidence}
                </div>
              );
            },
          },
        }}
        key={tableKey}
      />

      <AssigmentModal
        onCancel={() => setModal({ index: null, route: null, visible: false })}
        onFinish={(assignment) => {
          if (modal.index !== null && modal.route !== null) {
            mutateAsyncAssignment({
              data: { routeId: modal.route.id, assignment },
            }).then(() => {
              setModal({ index: null, route: null, visible: false });
              setTableKey(tableKey + 1);
            });
          }
        }}
        route={modal.route}
        visible={modal.visible && !!modal.route}
      />
    </Card>
  );
};

export default List;
