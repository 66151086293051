import React from 'react';

import { Typography } from 'antd';
import dayjs from 'dayjs';

interface Props {
  datetime?: null | string;
  format?: string;
  bold?: boolean;
}

export const DateFormatted: React.FC<Props> = (props) => {
  const { datetime, format = 'D MMM HH:mm', bold = false } = props;

  const font = bold ? 'bold' : 'normal';
  return (
    <Typography.Text style={{ fontWeight: font }}>
      {!!datetime ? dayjs(datetime).format(format) : 'No disponible'}
    </Typography.Text>
  );
};

export default React.memo(DateFormatted);
