import React, { useState, useEffect } from 'react';

import { Col, Row, Table as AntTable } from 'antd';
import { ColumnsType, TableProps as AntTableProps } from 'antd/es/table';
// import { identity, isEmpty, pickBy } from 'ramda';
import { useQuery } from 'react-query';
// import useDeepCompareEffect from 'use-deep-compare-effect';

import { TableSearchInput } from './TableSearchInput';
import { TableReloadButton } from './TableReloadButton';
import { fetch } from '../../lib';

interface Props<T> {
  actions?: React.ReactNode[];
  columns: ColumnsType<T>;
  queryParams?: Record<string, any>;
  tableProps?: AntTableProps<T>;
  searchPlaceHolder?: string;
  searchEnable?: boolean;
  url: string;
  bordered?: boolean;
  fetchedData?: Function;
  key?: number;
}

export default function Table<T extends object>(props: Props<T>) {
  const {
    actions,
    columns,
    queryParams = {},
    searchEnable = true,
    bordered = true,
    fetchedData,
    key,
  } = props;
  const { tableProps, url } = props;

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [search, setSearch] = useState<string>('');

  const { data, isFetching, isLoading, refetch } = useQuery(
    url,
    async () =>
      fetch.call(
        'GET',
        url,
        {},
        { params: { page, pageSize, search, ...queryParams } },
      ),
    { keepPreviousData: true },
  );

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      refetch().then(() => null);
    }
  }, [queryParams, refetch]);

  useEffect(() => {
    if (fetchedData) {
      fetchedData(data);
    }
  }, [data, fetchedData]);
  // const debounceCallback = useMemo(
  //   () => '',
  // debounce((qp: Record<string, any> | undefined) => {
  //   if (!isEmpty(pickBy(qp, identity))) {
  //     refetch().then(() => null);
  //   }
  // }, 1300),
  //   [refetch],
  // );

  // useDeepCompareEffect(() => {
  // @ts-ignore
  // debounceCallback(queryParams);
  // }, [queryParams]);

  return (
    <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
      <TableSearchInput
        enable={searchEnable}
        loading={isLoading || isFetching}
        placeholder={props.searchPlaceHolder}
        onSearch={async (value) => {
          await setSearch(value);
          await refetch();
        }}
      />
      <TableReloadButton
        actions={actions}
        loading={isLoading || isFetching}
        onReload={() => refetch()}
      />

      <Col style={{ marginTop: '15px' }} xs={{ span: 24, order: 3 }}>
        <AntTable<T>
          bordered={bordered}
          columns={columns}
          dataSource={data?.results || []}
          loading={isLoading || isFetching}
          onChange={async (pagination, filters, sorter) => {
            if (pagination?.current && pagination?.pageSize) {
              await setPage(pagination.current);
              await setPageSize(pagination.pageSize);
            }
            await refetch();
          }}
          pagination={{
            defaultCurrent: 1,
            defaultPageSize: 10,
            pageSizeOptions: ['10', '25', '50'],
            position: ['bottomCenter'],
            showSizeChanger: true,
            showTotal: (total) => `Total ${total}`,
            total: data?.total || 1,
          }}
          rowKey="id"
          size="small"
          scroll={{ x: 990 }}
          {...tableProps}
          key={key}
        />
      </Col>
    </Row>
  );
}
