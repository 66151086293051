import React from 'react';

// import { ReloadOutlined } from '@ant-design/icons';
// import { Button, Col, Space, Tooltip } from 'antd';
import { Col, Space } from 'antd';

interface props {
  actions?: Array<React.ReactNode>;
  loading: boolean;
  onReload: () => void;
}

export const TableReloadButton: React.FC<props> = ({
  actions = [],
  loading,
  onReload,
}) => (
  <React.Fragment>
    <Col
      md={{ span: 17, order: 2 }}
      sm={{ span: 16, order: 2 }}
      style={{ textAlign: 'right' }}
      xs={{ span: 24, order: 1 }}
    >
      <Space>
        {actions.map((action) => action)}

        {/*<Tooltip title="Recargar">*/}
        {/*  <Button*/}
        {/*    htmlType="button"*/}
        {/*    icon={<ReloadOutlined />}*/}
        {/*    loading={loading}*/}
        {/*    onClick={onReload}*/}
        {/*  />*/}
        {/*</Tooltip>*/}
      </Space>
    </Col>
  </React.Fragment>
);
