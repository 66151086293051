import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Col, Layout, Row } from 'antd';

import { DropdownActions } from '../DropdownActions';

import { session } from 'lib';
import logo from 'images/logo.svg';

interface Props {
  className?: string;
}

export const Header: React.FC<Props> = (props) => {
  const { className } = props;

  return (
    <Layout.Header className={className} style={{ backgroundColor: '#ffffff' }}>
      <Row justify="space-around">
        <Col span={8}>
          <img alt="logo" src={logo} />
        </Col>

        <Col span={8} style={{ textAlign: 'center' }} />

        <Col span={8} style={{ textAlign: 'right' }}>
          <DropdownActions
            menuOptions={[{ key: 'logout', name: 'Cerrar Sesión' }]}
            onMenuClick={() => {
              session.clearSession('access');
              window.location.reload();
            }}
          >
            <span style={{ lineHeight: '86px', marginLeft: '25px' }}>
              <UserOutlined style={{ fontSize: '20px' }} />
            </span>
          </DropdownActions>
        </Col>
      </Row>
    </Layout.Header>
  );
};
