import React from 'react';

import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';

import { Card, DropdownActions, Empty, Icons } from 'components';
import './RouteTypeSelector.css';

interface Props {
  onEditRoute: (index: number) => void;
  onNextStep: () => void;
  onTypeSelected: (type: 'multiple' | 'single' | 'fast') => void;
  routes: any[];
  currRouteType: 'multiple' | 'single' | 'fast' | undefined;
  isWaiver: boolean;
}

const RouteTypeSelector: React.FC<Props> = (props) => {
  const {
    onEditRoute,
    onNextStep,
    onTypeSelected,
    routes,
    currRouteType,
    isWaiver,
  } = props;

  const history = useHistory();

  const columns: ColumnsType<any> = [
    {
      key: 'route',
      render: (text, record) => (
        // <div style={{ alignItems: 'center', display: 'flex' }}>
        //   <span style={{ flex: 1 }}>{record?.origin?.label}</span>
        //   <ArrowRightOutlined style={{ flex: 1, fontSize: 24 }} />
        //   <span style={{ flex: 1 }}>{record?.destination?.label}</span>
        // </div>
        <div className="route-type-selector__origin-destination">
          <div>{record?.origin?.label}</div>

          <div className="route-type-selector_location-arrow">
            <ArrowRightOutlined />
          </div>

          <div>{record?.destination?.label}</div>
        </div>
      ),
      title: 'Viajes',
    },
    {
      align: 'center',
      key: 'edit',
      render: (text, record, index) => {
        return <EditOutlined onClick={() => onEditRoute(index)} />;
      },
      width: '50px',
    },
  ];
  const fastColumns: ColumnsType<any> = [
    {
      key: 'route',
      render: (text, record) => (
        <div className="route-type-selector__origin-destination">
          <div>{record?.origin?.address}</div>

          <div className="route-type-selector_location-arrow">
            <ArrowRightOutlined />
          </div>

          <div>{record?.destination?.address}</div>
        </div>
      ),
      title: 'Viajes',
    },
    {
      align: 'center',
      key: 'edit',
      render: (text, record, index) => {
        return <EditOutlined onClick={() => onEditRoute(index)} />;
      },
      width: '50px',
    },
  ];

  let menuOptions: { key: string; name: string; divider?: boolean }[] = [
    { key: 'single', name: 'Punto a punto' },
    { key: 'multiple', name: 'Reparto' },
  ];
  if (!isWaiver) {
    menuOptions.unshift({ key: 'fast', name: 'Rapido', divider: true });
  }

  return (
    <Row gutter={24}>
      <Card.Divider>Viajes</Card.Divider>

      <Col span={24} style={{ textAlign: 'right' }}>
        {!routes.length && (
          <DropdownActions
            dropdownProps={{
              disabled: !!routes.length,
            }}
            menuOptions={menuOptions}
            // menuOptions={[
            //   { key: 'fast', name: 'Rapido', divider: true },
            //   { key: 'single', name: 'Punto a punto' },
            //   { key: 'multiple', name: 'Reparto' },
            // ]}
            onMenuClick={({ key }) => onTypeSelected(key)}
          >
            <Button icon={<PlusOutlined />} type="primary">
              Agregar viajes
            </Button>
          </DropdownActions>
        )}
      </Col>

      <Col span={24}>
        {!routes.length ? (
          <Empty
            description="¿Cuántas rutas desea agregar hoy?"
            title="¡Hola!"
            image={<Icons.Smile />}
          />
        ) : (
          <Table
            bordered={true}
            columns={currRouteType !== 'fast' ? columns : fastColumns}
            dataSource={routes}
            pagination={false}
            size="small"
            rowKey={() => nanoid()}
            style={{ margin: '20px 0' }}
          />
        )}
      </Col>

      <Divider />

      <Col span={24} style={{ textAlign: 'right' }}>
        <Space>
          <Button
            onClick={() => {
              const url = isWaiver ? '/shipments/waiver' : '/shipments';
              history.push(url);
            }}
          >
            Cancelar
          </Button>
          <Button
            disabled={!routes.length}
            type="primary"
            onClick={() => onNextStep()}
          >
            Siguiente
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default RouteTypeSelector;
