import React from 'react';
import ReactDOM from 'react-dom';

import { LoadScript } from '@react-google-maps/api';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import './index.css';

import { AuthProvider } from './context';
import reportWebVitals from './reportWebVitals';
import { App } from './pages/App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: false },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />

      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY || ''}
        language="es"
        libraries={['geometry', 'places']}
        region="MX"
      >
        <ConfigProvider
          form={{ validateMessages: { required: 'Requerido' } }}
          locale={esES}
        >
          <AuthProvider>
            <App />
          </AuthProvider>
        </ConfigProvider>
      </LoadScript>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
