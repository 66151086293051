import { fetch } from '../../lib';
import { AccountContact } from '../../models';

interface Variables {
  meta: { baseUrl: string };
  params: { accountContactId: number | string };
}

export const getAccountContact = ({ meta, params }: Variables) =>
  fetch.call<AccountContact>(
    'GET',
    `${meta.baseUrl}/contacts/${params.accountContactId}`,
  );
