import React from 'react';

import { Collapse } from 'antd';

import './UniqueCollapsable.css';

interface Props {
  borderColor?: 'black' | 'blue' | 'green' | 'red';
  children: React.ReactNode;
  extra?: React.ReactNode;
  header: React.ReactNode;
  active?: boolean;
}

const UniqueCollapsable: React.FC<Props> = (props) => {
  const {
    borderColor = 'transparent',
    children,
    extra,
    header,
    active,
  } = props;

  return (
    <Collapse
      bordered={false}
      className="unique-collapsable"
      defaultActiveKey={active ? 1 : 0}
    >
      <Collapse.Panel
        className={`unique-collapsable__header-${borderColor}`}
        extra={extra}
        header={header}
        key="1"
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

export default UniqueCollapsable;
