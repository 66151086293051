import AccountPaper from './AccountPaper';
import Alert from './Alert';
import Check from './Check';
import Excel from './Excel';
import MapPin from './MapPin';
import MapPinTracker from './MapPinTracker';
import MapRoute from './MapRoute';
import Settings from './Settings';
import Smile from './Smile';
import UserGroup from './UserGroup';

const Icons = {
  AccountPaper,
  Alert,
  Check,
  Excel,
  MapPin,
  MapPinTracker,
  MapRoute,
  Settings,
  Smile,
  UserGroup,
};

export default Icons;
