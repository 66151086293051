import { fetch } from 'lib';
import { CarrierConfig, ID } from 'models';

interface Variables {
  data: CarrierConfig;
  params: { accountId: ID };
}

export const addCarrierConfig = ({ data, params }: Variables) =>
  fetch.call('POST', `/accounts/carriers/${params.accountId}/configs`, data);
