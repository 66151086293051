import { Account, Truck } from 'models';
import { Link, useHistory } from 'react-router-dom';
import { TableList, UniqueCollapsable } from 'components';

import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';

interface Props {
  account: Account;
  baseUrl: string;
  active?:boolean;
}

const TruckList: React.FC<Props> = (props) => {
  const { account, baseUrl, active } = props;

  const history = useHistory();

  const columns: ColumnsType<Truck> = [
    {
      dataIndex: ['name'],
      key: 'name',
      render: (text, record) => (
        <Link to={`${baseUrl}/${account.id}/trucks/${record.id}/edit`}>
          <span>{record.name}</span>
        </Link>
      ),
      title: 'Num. económico',
    },
    {
      dataIndex: ['plates'],
      key: 'plates',
      title: 'Placas',
    },
    {
      dataIndex: ['truckType', 'name'],
      key: 'truckTypeName',
      title: 'Tipo',
    },
    {
      dataIndex: ['truckStatus', 'name'],
      key: 'truckStatusName',
      title: 'Estatus',
    },
  ];

  const extra = (
    <Button
      onClick={() =>
        history.push(`${baseUrl}/${account.id}/trucks/add`, {
          account: account,
          baseUrl: baseUrl,
        })
      }
      size="small"
    >
      Nuevo
    </Button>
  );

  return (
    <UniqueCollapsable extra={extra} header="Unidades" active={active}>
      <TableList<Truck>
        columns={columns}
        historyPush={{
          state: { account },
          url: `${baseUrl}/${account.id}/trucks`,
        }}
        url={`${baseUrl}/${account.id}/trucks`}
      />
    </UniqueCollapsable>
  );
};

export default TruckList;
