import React from 'react';

import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import * as api from 'api';
import { Card, FormWrapper } from 'components';
import { Account } from 'models';

import PageForm from './PageForm';

interface Props {
  baseUrl: string;
  onCancel?: () => void;
  onSuccess?: () => void;
  title: string;
}

const EditWrapper: React.FC<Props> = (props) => {
  const { baseUrl, onCancel, onSuccess, title } = props;

  const { accountId } = useParams<{ accountId: string }>();

  const { data, isLoading } = useQuery(`getAccount__${accountId}`, () =>
    api.getAccount({
      meta: { baseUrl: baseUrl },
      params: { accountId: accountId },
    }),
  );
  const { mutateAsync } = useMutation(api.updateAccount);

  const handleFinish = async (values: Record<string, any>) => {
    if (!data?.id) {
      return;
    }

    (await mutateAsync({
      data: values as Account,
      meta: { baseUrl: baseUrl },
      params: { accountId: data.id },
    })) && onSuccess?.();
  };

  return (
    <Card bodyStyle={{ margin: 'auto', width: '80%' }} loading={isLoading}>
      <FormWrapper
        initialValues={data}
        layout="vertical"
        onFinish={handleFinish}
        onCancel={() => onCancel?.()}
      >
        <PageForm title={title} />
      </FormWrapper>
    </Card>
  );
};

export default EditWrapper;
