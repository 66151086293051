import React, { useState, useEffect } from 'react';

import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Space, Tag, Upload } from 'antd';
import { Route } from 'models';

import TextArea from 'antd/lib/input/TextArea';
import './Evidence.css';

interface Props {
  onCancel: () => void;
  onFinish: (evidence: any) => void;
  visible: boolean;
  route: Route | undefined;
  index: number | undefined;
  isLoading: boolean;
}

const Evidence: React.FC<Props> = (props) => {
  const { onCancel, onFinish, visible, route, index, isLoading } = props;

  const [form] = Form.useForm();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [preview, setPreview] = useState<{
    image: any;
    title: string;
    status: any;
  }>();
  const [fileList, setFileList] = useState<{
    cartaporte: any[];
    signature: any[];
    evidences: any[];
  }>({ cartaporte: [], signature: [], evidences: [] });

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API_URL;

    const ev = route?.evidences.map((ev) => {
      return { url: `${baseUrl}/evidences/${ev.filename}` };
    });
    const newList = {
      cartaporte: route?.cartaporte
        ? [{ url: `${baseUrl}/cartaporte/${route?.cartaporte?.filename}` }]
        : [],
      signature: route?.signature
        ? [{ url: `${baseUrl}/signature/${route?.signature?.filename}` }]
        : [],
      evidences: ev || [],
    };

    setFileList(newList);
  }, [route]);

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreview({
      image: file.url || file.preview,
      status: file.status,
      title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
    setPreviewVisible(true);
  };

  const handleChange = (
    value: any,
    name: 'cartaporte' | 'signature' | 'evidences',
  ) => {
    let sfileList = [...value.fileList];
    const newList = { ...fileList, [name]: sfileList };
    setFileList(newList);
  };

  const handleCancel = () => {
    form.resetFields();
    setPreview({ image: '', title: '', status: preview?.status });

    //Restore input values
    const baseUrl = process.env.REACT_APP_API_URL;

    const ev = route?.evidences.map((ev) => {
      return { url: `${baseUrl}/evidences/${ev.filename}` };
    });
    const newList = {
      cartaporte: route?.cartaporte
        ? [{ url: `${baseUrl}/cartaporte/${route?.cartaporte?.filename}` }]
        : [],
      signature: route?.signature
        ? [{ url: `${baseUrl}/signature/${route?.signature?.filename}` }]
        : [],
      evidences: ev || [],
    };

    setFileList(newList);

    onCancel();
  };

  const handleCancelPreview = () => {
    setPreviewVisible(false);
  };

  const handleFinish = () => {
    form.validateFields().then(() => {
      const data = form.getFieldsValue();
      onFinish({ ...data, routeId: route?.id });
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Modal
      closable={false}
      destroyOnClose={true}
      footer={
        <Space>
          <Button disabled={isLoading} onClick={handleCancel} type="default">
            Cancelar
          </Button>
          <Button disabled={isLoading} onClick={handleFinish} type="primary">
            Guardar
          </Button>
        </Space>
      }
      title="Asignación de evidencia"
      visible={visible}
    >
      <Tag className="evidence__routes">
        <div>
          {index} -{' '}
          {route?.originLocation?.address ||
            route?.extra.origin?.address ||
            'undefined'}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ArrowRightOutlined />
        </div>

        <div>
          {route?.destinationLocation?.address ||
            route?.extra.destination?.address ||
            'undefined'}
        </div>
      </Tag>
      <Form
        initialValues={{ ...route }}
        form={form}
        layout="vertical"
        preserve={true}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Carta porte"
              className="evidence__upload-item"
              name={['cartaporte']}
            >
              <Upload
                listType="picture-card"
                fileList={fileList.cartaporte}
                onPreview={handlePreview}
                onChange={(value) => {
                  handleChange(value, 'cartaporte');
                }}
                customRequest={({ file, onSuccess }) => {
                  setTimeout(() => {
                    onSuccess!!('ok', new XMLHttpRequest());
                  }, 0);
                }}
              >
                {fileList.cartaporte.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Firma"
              className="evidence__upload-item"
              name={['signature']}
            >
              <Upload
                listType="picture-card"
                fileList={fileList.signature}
                onPreview={handlePreview}
                onChange={(value) => {
                  handleChange(value, 'signature');
                }}
                customRequest={({ file, onSuccess }) => {
                  setTimeout(() => {
                    onSuccess!!('ok', new XMLHttpRequest());
                  }, 0);
                }}
              >
                {fileList.signature.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Cargar evidencias" name={['evidences']}>
              <Upload
                listType="picture-card"
                fileList={fileList.evidences}
                onPreview={handlePreview}
                onChange={(value) => handleChange(value, 'evidences')}
                customRequest={({ file, onSuccess }) => {
                  setTimeout(() => {
                    onSuccess!!('ok', new XMLHttpRequest());
                  }, 0);
                }}
              >
                {fileList.evidences.length >= 4 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Agregar comentarios" name={['comments']}>
          <TextArea autoSize={{ minRows: 3, maxRows: 3 }}></TextArea>
        </Form.Item>
      </Form>
      <Modal
        visible={previewVisible}
        title={
          <p
            style={{
              margin: 0,
              color: preview?.status === 'error' ? 'red' : '',
            }}
          >
            {preview?.title}
          </p>
        }
        footer={null}
        onCancel={handleCancelPreview}
      >
        <img alt="example" style={{ width: '100%' }} src={preview?.image} />
      </Modal>
    </Modal>
  );
};

export default Evidence;
