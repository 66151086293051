import React from 'react';

interface Props {
  style?: React.CSSProperties;
}

const Smile: React.FC<Props> = (props) => {
  return (
    <svg
      fill="#bdbdbd"
      height="54.262"
      style={props.style}
      viewBox="0 0 54.262 54.262"
      width="54.262"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M27.131,0A27.131,27.131,0,1,0,54.262,27.131,27.129,27.129,0,0,0,27.131,0ZM43.576,43.576a23.25,23.25,0,0,1-39.7-16.445A23.24,23.24,0,0,1,27.131,3.875,23.24,23.24,0,0,1,50.387,27.131a23.2,23.2,0,0,1-6.812,16.445Z" />
      <path
        d="M0,3.881A3.881,3.881,0,1,0,1.137,1.137,3.881,3.881,0,0,0,0,3.881Z"
        transform="translate(34.881 15.512)"
      />
      <path
        d="M0,3.881A3.881,3.881,0,1,0,1.137,1.137,3.881,3.881,0,0,0,0,3.881Z"
        transform="translate(11.631 15.512)"
      />
      <path
        d="M15.476,15.482A15.483,15.483,0,0,0,30.958,0H0A15.47,15.47,0,0,0,15.476,15.482Z"
        transform="translate(11.661 31.006)"
      />
    </svg>
  );
};

export default Smile;
