import { fetch } from '../../lib';
import { AccountContact } from '../../models';

interface Variables {
  data: Omit<AccountContact, 'id' | 'isRepresentative'>;
  meta: { baseUrl: string };
}

export const addAccountContact = ({ data, meta }: Variables) => {
  const { accountJobPosition, accountLocation, ...rest } = data;
  return fetch.call('POST', `${meta.baseUrl}/contacts`, {
    ...rest,
    accountJobPositionId: accountJobPosition.id,
    accountLocationId: accountLocation.id,
  });
};
