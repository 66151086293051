import React from 'react';

import { Checkbox, Col, Form, InputNumber, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';

import { AddressInputs, Card, SelectSearch } from 'components';
import { ShipmentModalType, ShipmentNoteGlobal, TruckType } from 'models';

interface Props {
  accountType: 'carrier' | 'client' | 'owner';
  form: FormInstance;
  initialData?: ShipmentNoteGlobal;
  title: string;
}

const PageForm: React.FC<Props> = (props) => {
  const { accountType, form, initialData, title } = props;

  return (
    <React.Fragment>
      <Card.Title title={title} />

      <Row gutter={24}>
        <Card.Divider>Origen</Card.Divider>
        <AddressInputs.CascadeOnly form={form} namePathKey="originCity" />

        <Card.Divider>Destino</Card.Divider>

        <AddressInputs.CascadeOnly form={form} namePathKey="destinationCity" />

        <Card.Divider>Configuración del flete</Card.Divider>

        <Col span={6}>
          <SelectSearch<ShipmentModalType>
            formItemProps={{
              label: 'Tipo de flete',
              name: ['shipmentModalType', 'id'],
              rules: [{ required: true }],
            }}
            initialData={
              !!initialData?.shipmentModalType
                ? [initialData.shipmentModalType]
                : []
            }
            renderOption={(option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            )}
            url="/catalogs/shipment-modal-type"
          />
        </Col>

        <Col span={6}>
          <SelectSearch<TruckType>
            formItemProps={{
              name: ['truckType', 'id'],
              label: 'Tipo de unidad',
              rules: [{ required: true }],
            }}
            initialData={
              !!initialData?.truckType ? [initialData.truckType] : []
            }
            renderOption={(option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            )}
            url="/catalogs/truck-type"
          />
        </Col>

        <Col span={6}>
          <Form.Item name={['cost']} label="Costo" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        {accountType === 'carrier' && (
          <Col span={6}>
            <Form.Item
              name={['isReturnedEmpty']}
              label="Regreso vacío"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default PageForm;
