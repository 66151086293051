import React from 'react';

import { Button, Col, Divider, Row } from 'antd';

import { Title } from '../../components';

export const FinishStep = () => {
  return (
    <Row style={{ marginLeft: 'auto', marginRight: 'auto', width: '80%' }}>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Title
          subTitles={[
            'Estamos verificando su información',
            'Un representante se comunicará con usted para validar toda ' +
              'la información dada de alta en nuestro sistema.',
          ]}
          title={'GRACIAS POR COMPLETAR SU REGISTRO'}
        />
      </Col>

      <Col span={24}>
        <Divider />
      </Col>

      <Col span={24} style={{ textAlign: 'right' }}>
        <Button>Regresar al portal</Button>
      </Col>
    </Row>
  );
};
