import React from 'react';

import { Col, Typography } from 'antd';

import './Title.css';

export interface Props {
  subTitle?: React.ReactNode;
  title: React.ReactNode;
}

export const Title: React.FC<Props> = (props) => (
  <Col span={24}>
    <Typography.Title className="card-title__title" level={4}>
      {props.title}
    </Typography.Title>
    {!!props.subTitle && (
      <Typography.Paragraph className="card-title__sub-title">
        {props.subTitle}
      </Typography.Paragraph>
    )}
  </Col>
);
