import { fetch } from '../../lib';
import { Account } from '../../models';

interface Variables {
  meta: { baseUrl: string };
  params: { accountId: number | string };
}

export const getAccount = ({ meta, params }: Variables) =>
  fetch.call<Account>('GET', `${meta.baseUrl}/${params.accountId}`);
