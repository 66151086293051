import { fetch } from 'lib';
import { ID } from 'models';

interface Variables {
  params: { accountId: ID; carrierConfigId: ID };
}

export const getCarrierConfig = ({ params }: Variables) =>
  fetch.call(
    'GET',
    `/accounts/carriers/${params.accountId}/configs/${params.carrierConfigId}`,
  );
