import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, useHistory } from 'react-router-dom';

import { Card, Table } from 'components';
import { useAccountUrl } from 'hooks';
import { CarrierConfig } from 'models';

const List = () => {
  const urls = useAccountUrl();
  const history = useHistory();

  const baseApiUrl = `${urls.baseApiUrl}/configs`;
  const baseUrl = `${urls.baseUrl}/configs`;

  const actions = [
    <Button
      icon={<PlusOutlined />}
      key="add"
      onClick={() => history.push(`${baseUrl}/add`)}
      type="primary"
    >
      Agregar Configuración
    </Button>,
  ];

  const columns: ColumnsType<CarrierConfig> = [
    {
      dataIndex: ['mirrorWebsite'],
      key: 'mirrorWebsite',
      render: (text, record) => (
        <Link to={`${baseUrl}/${record.id}/edit`}>
          <span>{record.mirrorWebsite}</span>
        </Link>
      ),
      title: 'Pagina web',
    },
    {
      dataIndex: ['mirrorUser'],
      key: 'mirrorUser',
      title: 'Usuario',
    },
    {
      dataIndex: ['mirrorPassword'],
      key: 'mirrorPassword',
      title: 'Contraseña',
    },
  ];

  return (
    <Card>
      <Card.Title
        subTitle="Listado de configuraciones"
        title="Configuraciones"
      />

      <Table<CarrierConfig>
        actions={actions}
        columns={columns}
        url={baseApiUrl}
      />
    </Card>
  );
};

export default List;
