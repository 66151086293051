import React from 'react';

import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuth } from '../../hooks';

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  let auth = useAuth();

  if (!auth.user) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  return <Route {...props} />;
};
