import React from 'react';

import { ProFormCheckbox, ProFormText } from '@ant-design/pro-form';
import { Col, Form, Input, Row, Select } from 'antd';

import { Card, DatePicker, SelectQuery } from 'components';
import { DriverLicenseType } from 'models';

interface Props {
  readonly?: boolean;
  title?: string;
}

const PageForm: React.FC<Props> = (props) => {
  const { readonly = false, title } = props;

  return (
    <Row gutter={24}>
      {title && <Card.Title title={title} />}

      <Card.Divider>Información general</Card.Divider>

      <Form.Item
        name={['accountContact', 'id']}
        label="Nombre"
        style={{ display: 'none' }}
      >
        <Input />
      </Form.Item>

      <Col span={8}>
        <Form.Item
          name={['accountContact', 'firstName']}
          label="Nombre"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name={['accountContact', 'lastName']}
          label="Apellido"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={8} />

      <Col span={8}>
        <Form.Item
          name={['accountContact', 'email']}
          label="Email"
          rules={[{ type: 'email' }, { required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={16} />

      <Col span={8}>
        <Form.Item
          name={['accountContact', 'phone']}
          label="Teléfono"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name={['accountContact', 'phoneMobile']}
          label="Celular"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Card.Divider>Licencia de conducir</Card.Divider>

      <Col span={8}>
        <SelectQuery<DriverLicenseType>
          formItemProps={{
            name: ['driverLicenseType', 'id'],
            label: 'Tipo de licencia',
            rules: [{ required: true }],
          }}
          renderOption={(option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          )}
          url="/catalogs/driver-license-type"
        />
      </Col>

      <Col span={8}>
        <ProFormText
          name={['driverLicense']}
          label="N° de licencia"
          readonly={readonly}
          rules={[{ required: true }]}
        />
      </Col>

      <Col span={8}>
        <Form.Item
          label="Expiración"
          name={['driverExpirationDate']}
          rules={[{ required: true }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <ProFormCheckbox
          formItemProps={{ className: 'form-item-check-inline' }}
          name={['isCertified']}
          label="¿Esta certificado?"
        />
      </Col>
    </Row>
  );
};

export default PageForm;
