import React from 'react';

import { Col, Input } from 'antd';

interface Props {
  enable: boolean;
  loading: boolean;
  onSearch: (value: string) => void;
  placeholder?: string;
}

export const TableSearchInput: React.FC<Props> = (props) => (
  <Col
    md={{ span: 7, order: 1 }}
    sm={{ span: 8, order: 1 }}
    xs={{ span: 24, order: 2 }}
  >
    {props.enable && (
      <Input.Search
        disabled={props.loading}
        enterButton={true}
        loading={props.loading}
        onSearch={(value) => props.onSearch(value)}
        placeholder={props.placeholder || 'Buscar...'}
      />
    )}
  </Col>
);
