import React from 'react';

import { Card, Form } from 'antd';
import { useMutation } from 'react-query';

import * as api from 'api';
import { FormWrapper } from 'components';
import { useAccountUrl } from 'hooks';
import { ShipmentNoteGlobal } from 'models';

import PageForm from './PageForm';

interface Props {
  onCancel?: () => void;
  onSuccess?: () => void;
}

const AddWrapper: React.FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;

  const urls = useAccountUrl();
  const [form] = Form.useForm();
  const { mutateAsync } = useMutation(api.addShipmentNote);

  const handleFinish = async (values: Record<string, any>) => {
    (await mutateAsync({
      data: {
        ...values,
        destinationCity: { id: values.destinationCity?.city?.value },
        originCity: { id: values.originCity?.city?.value },
      } as ShipmentNoteGlobal,
      meta: { baseUrl: urls.baseApiUrl },
    })) && onSuccess?.();
  };

  return (
    <Card bodyStyle={{ margin: 'auto', width: '80%' }}>
      <FormWrapper
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onCancel={() => onCancel?.()}
      >
        <PageForm
          accountType={urls.accountType}
          form={form}
          title="Agregar nota de flete"
        />
      </FormWrapper>
    </Card>
  );
};

export default AddWrapper;
