export function clearSession(key: string) {
  sessionStorage.removeItem(key);
}

export function getSession(key: string) {
  return sessionStorage.getItem(key);
}

export function setSession(key: string, value: string) {
  sessionStorage.setItem(key, value);
}
