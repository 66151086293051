import { fetch } from 'lib';
import { AccountLocation } from 'models';

interface Variables {
  data: AccountLocation;
  meta: { baseUrl: string };
  params: { accountLocationId: number | string };
}

export const updateAccountLocation = ({ data, meta, params }: Variables) => {
  const { accountActiveType, accountOwnership, city, country, ..._rest } = data;
  const { state, ...rest } = _rest;
  return fetch.call(
    'PUT',
    `${meta.baseUrl}/locations/${params.accountLocationId}`,
    {
      ...rest,
      cityId: city.value,
    },
  );
};
