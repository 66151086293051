import React from 'react';

import { Empty as AntEmpty, EmptyProps } from 'antd';

import './Empty.css';

interface Props {
  description?: React.ReactNode;
  emptyProps?: EmptyProps;
  image: React.ReactNode;
  title: React.ReactNode;
}

const Empty: React.FC<Props> = (props) => (
  <AntEmpty
    description={
      <>
        <p>{props.title}</p>
        <p>{props.description}</p>
      </>
    }
    className="empty__container"
    image={props.image}
    {...props.emptyProps}
  />
);

export default Empty;
