import React from 'react';

import './AccountRegister.css';

import { RegisterForm } from './RegisterForm';

interface Props {
  initialValues: any;
  setStep: (formData: any, nextStep: number) => void;
}

export const RegisterStep: React.FC<Props> = (props) => {
  const { initialValues, setStep } = props;

  return (
    <RegisterForm
      disabled={false}
      initialValues={initialValues}
      onNextStep={(formData) => setStep(formData, 1)}
      title={'REGISTRO DE EMPRESAS'}
      subTitles={['Complete los datos siguientes para continuar']}
    />
  );
};
