import React, { useState } from 'react';

import { ApiOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMutation } from 'react-query';

import * as api from 'api';
import { Card, FormWrapper, Table } from 'components';
import { useAccountUrl } from 'hooks';
import { AccountContact, Driver } from 'models';

import PageForm from './PageForm';

interface Props {
  onCancel?: () => void;
  onSuccess?: () => void;
}

const AddWrapper: React.FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;

  const urls = useAccountUrl();
  const { mutateAsync } = useMutation(api.addDriver);
  const [contact, setContact] = useState<AccountContact | null | undefined>();

  const { accountId } = urls;

  const handleFinish = async (values: Record<string, any>) => {
    (await mutateAsync({
      data: values as Driver,
      meta: { baseUrl: urls.baseApiUrl },
    })) && onSuccess?.();
  };

  if (contact === undefined) {
    const actions = [
      <Button
        icon={<UserAddOutlined />}
        key="add"
        onClick={() => setContact(null)}
        type="primary"
      >
        Crear operador
      </Button>,
    ];

    const columns: ColumnsType<AccountContact> = [
      {
        dataIndex: ['firstName'],
        key: 'firstName',
        title: 'Nombre',
      },
      {
        dataIndex: ['lastName'],
        key: 'lastName',
        title: 'Apellido',
      },
      {
        dataIndex: ['email'],
        key: 'email',
        title: 'Email',
      },
      {
        dataIndex: ['accountJobPosition', 'name'],
        key: 'accountJobPositionName',
        title: 'Cargo',
      },
      {
        dataIndex: ['accountLocation', 'name'],
        key: 'accountLocationName',
        title: 'Base operativa',
      },
      {
        align: 'center',
        key: 'actions',
        render: (text, record) => {
          return (
            <Space>
              <ApiOutlined onClick={() => setContact(record)} />
            </Space>
          );
        },
      },
    ];

    return (
      <Card bodyStyle={{ margin: 'auto', width: '80%' }}>
        <Card.Title
          subTitle="Busque en sus contactos el operador para asignarlo o
            crearlo desde el botón de acción"
          title="Agregar operador"
        />
        <Table<AccountContact>
          actions={actions}
          columns={columns}
          tableProps={{
            scroll: undefined,
          }}
          url={`/accounts/contacts/available/drivers?accountId=${accountId}`}
        />
      </Card>
    );
  }

  return (
    <Card bodyStyle={{ margin: 'auto', width: '80%' }}>
      <FormWrapper
        initialValues={{
          accountContact: contact,
          isCertified: false,
        }}
        layout="vertical"
        onFinish={handleFinish}
        onCancel={() => onCancel?.()}
      >
        <PageForm title="Agregar operador" />
      </FormWrapper>
    </Card>
  );
};

export default AddWrapper;
