import React from 'react';

import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { Card, Table } from 'components';
import { useAccountUrl } from 'hooks';
import { Route } from 'models';

const List = () => {
  const urls = useAccountUrl();

  const baseApiUrl = `${urls.baseApiUrl}/routes`;
  const baseUrl = `${urls.baseUrl}/routes`;

  const columns: ColumnsType<Route> = [
    {
      dataIndex: ['folio'],
      key: 'folio',
      render: (text, record) => (
        <Link to={`${baseUrl}/${record.id}/detail`}>
          <span>{record.customerOrder}</span>
        </Link>
      ),
      title: 'Folio',
    },
  ];

  return (
    <Card>
      <Card.Title subTitle="Listado de fletes" title="Fletes" />
      <Table<Route> columns={columns} url={baseApiUrl} />
    </Card>
  );
};

export default List;
