import { Account, Driver } from 'models';
import { Link, useHistory } from 'react-router-dom';
import { TableList, UniqueCollapsable } from 'components';

import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';

interface Props {
  account: Account;
  baseUrl: string;
  active?:boolean;
}

export const DriverList: React.FC<Props> = (props) => {
  const { account, baseUrl,active } = props;

  const history = useHistory();

  const columns: ColumnsType<Driver> = [
    {
      key: 'firstName',
      render: (text, record) => {
        return (
          <Link to={`${baseUrl}/${account.id}/drivers/${record.id}/edit`}>
            {record.firstName}
          </Link>
        );
      },
      title: 'Nombre(s)',
    },
    {
      dataIndex: ['lastName'],
      key: 'lastName',
      title: 'Apellido(s)',
    },
    {
      dataIndex: ['email'],
      key: 'email',
      title: 'Email',
    },
    {
      dataIndex: ['phone'],
      key: 'phone',
      title: 'Teléfono',
    },
    {
      dataIndex: ['phoneMobile'],
      key: 'phoneMobile',
      title: 'Celular',
    },
  ];

  const extra = (
    <Button
      // onClick={() => history.push(`${baseUrl}/${account.id}/drivers/add`)}
      onClick={() =>
          history.push(`${baseUrl}/${account.id}/drivers/add`, {
          account: account,
          baseUrl: baseUrl,
        })
      }
      size="small"
    >
      Nuevo
    </Button>
  );

  return (
    <UniqueCollapsable extra={extra} header="Operadores" active={active}>
      <TableList<Driver>
        columns={columns}
        historyPush={{
          state: { account },
          url: `${baseUrl}/${account.id}/drivers`,
        }}
        url={`${baseUrl}/${account.id}/drivers`}
      />
    </UniqueCollapsable>
  );
};

export default DriverList;
