import React from 'react';

import { useMutation } from 'react-query';

import './AccountRegister.css';

import { RegisterForm } from './RegisterForm';

import * as api from '../../api';

interface Props {
  initialValues: any;
  setStep: (nextStep: number) => void;
}

export const VerifyStep: React.FC<Props> = (props) => {
  const { initialValues, setStep } = props;

  const { isLoading, mutateAsync } = useMutation(api.registerAccount);

  const handleCreate = () => {
    mutateAsync({ data: initialValues }).then(() => {
      setStep(2);
    });
  };

  return (
    <RegisterForm
      disabled={true}
      initialValues={initialValues}
      isLoading={isLoading}
      onNextStep={handleCreate}
      onPrevStep={() => setStep(0)}
      title={'VERIFIQUE SU INFORMACIÓN'}
      subTitles={[
        'Antes de continuar verifique toda la su información capturada',
      ]}
    />
  );
};
