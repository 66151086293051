import React from 'react';

import ProForm, { ProFormProps } from '@ant-design/pro-form';
import { Button, Divider, Space } from 'antd';

interface Props extends ProFormProps {
  onCancel: () => void;
}

export const FormWrapper: React.FC<Props> = (props) => {
  const { onCancel, ...rest } = props;
  return (
    <ProForm
      {...rest}
      submitter={{
        render: (props) => {
          return (
            <React.Fragment>
              <Divider />
              <div style={{ textAlign: 'right' }}>
                <Space>
                  <Button onClick={() => onCancel()}>Cancelar</Button>
                  <Button onClick={() => props.form?.submit?.()} type="primary">
                    Guardar
                  </Button>
                </Space>
              </div>
            </React.Fragment>
          );
        },
      }}
    >
      {props.children}
    </ProForm>
  );
};
