import React, { useState } from 'react';

import { Button, Col, Divider, Row, Space } from 'antd';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import * as api from 'api';
import { Card, Shipment } from 'components';

interface Props {
  onPrevStep: () => void;
  routes: any[];
  routeType: 'multiple' | 'single' | 'fast' | undefined;
  isWaiver: boolean;
}

const SummaryStep: React.FC<Props> = (props) => {
  const { onPrevStep, routes, routeType, isWaiver } = props;

  const history = useHistory();
  const { isLoading, mutateAsync } = useMutation(api.addShipment);
  const {
    isLoading: isLoadingFast,
    mutateAsync: mutateAsyncFast,
  } = useMutation(api.addFastShipment);
  const {
    // isLoading: isLoadingWaiver,
    mutateAsync: mutateAsyncWaiver,
  } = useMutation(api.addWaiverShipment);

  const [routesCached, setRouteCached] = useState<any[]>(routes);

  return (
    <Row gutter={24}>
      <Card.Divider>Resumen</Card.Divider>

      <Col span={24}>
        <Shipment.PageDetail
          onReceiverChange={(receivers, index) => {
            const routesTmp = [...routesCached];
            routesTmp[index].receivers = receivers;
            setRouteCached(routesTmp);
          }}
          readonly={false}
          routes={routes}
          routeType={routeType}
          isWaiver={isWaiver}
        />
      </Col>

      <Divider />

      <Col span={24} style={{ textAlign: 'right' }}>
        <Space>
          <Button
            disabled={routeType !== 'fast' ? isLoading : isLoadingFast}
            onClick={() => onPrevStep()}
          >
            Cancelar
          </Button>
          <Button
            loading={routeType !== 'fast' ? isLoading : isLoadingFast}
            onClick={() => {
              if (routeType !== 'fast') {
                console.log('is not Fast');

                if (isWaiver) {
                  console.log('isWaiver');
                  mutateAsyncWaiver({ data: { routes: routesCached } }).then(
                    () => {
                      history.push('/shipments/waiver');
                    },
                  );
                } else {
                  console.log('isNOT waiver');
                  mutateAsync({ data: { routes: routesCached } }).then(() => {
                    history.push('/shipments');
                  });
                }
              } else {
                mutateAsyncFast({ data: { routes: routesCached } });
              }
            }}
            type="primary"
          >
            Finalizar
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default SummaryStep;
