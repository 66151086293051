import { fetch } from 'lib';

interface Variables {
  data: any;
}

export const addAssignment = ({ data }: Variables) => {
  const { driver, trailer1, trailer2, truck } = data.assignment;

  return fetch.call('POST', `accounts/routes/${data.routeId}/assignment`, {
    accountTruckId: truck.value,
    driverId: driver.value,
    trailer1Id: trailer1?.value,
    trailer2Id: trailer2?.value,
  });
};
