import { fetch } from '../../lib';
import { Driver } from '../../models';

interface Variables {
  meta: { baseUrl: string };
  params: { driverId: number | string };
}

export const getDriver = ({ meta, params }: Variables) =>
  fetch.call<Driver>('GET', `${meta.baseUrl}/drivers/${params.driverId}`);
