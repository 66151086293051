import React from 'react';

import { Col, Divider, Row, Space } from 'antd';

import './PageDetail.css';
import RouteDetail from './RouteDetail';

interface Props {
  onReceiverChange?: (receivers: string[], index: number) => void;
  routes: any;
  routeType?: 'multiple' | 'single' | 'fast' | undefined;
  readonly?: boolean;
  isWaiver: boolean;
}

const PageDetail: React.FC<Props> = (props) => {
  const {
    onReceiverChange,
    readonly = true,
    routes,
    routeType,
    isWaiver,
  } = props;

  const saleTotalCost = routes.reduce((acc: any, curr: any) => {
    acc = acc + Number(curr.routeSale);
    return acc;
  }, 0);
  const totalCost = routes.reduce((acc: any, curr: any) => {
    acc = acc + Number(curr.routeCost);
    return acc;
  }, 0);

  return (
    <Row className="shipment-detail__container" gutter={24}>
      {routes.map((route: any, index: number) => (
        <React.Fragment key={index}>
          <Col span={24}>
            <RouteDetail
              onReceiverChange={(receivers) =>
                onReceiverChange?.(receivers, index)
              }
              route={route}
              readonly={readonly}
              routeType={routeType}
              isWaiver={isWaiver}
            />
          </Col>
          {index === routes.length - 1 ? (
            <Divider className="last-divider" />
          ) : (
            <Divider />
          )}
        </React.Fragment>
      ))}

      <Col className="summary-total" span={24}>
        <Space align="start" size={25}>
          <div>Total</div>
          <div>
            <div>Costo total</div>
            <div>{totalCost}</div>
          </div>
          {!isWaiver && (
            <div>
              <div>Venta total</div>
              <div>{saleTotalCost}</div>
            </div>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default PageDetail;
