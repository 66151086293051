import React from 'react';

import PageForm, { ProFormSelect, ProFormText } from '@ant-design/pro-form';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import { pipe, path, split } from 'ramda';

import './RouteDetail.css';

interface Props {
  onReceiverChange?: (receivers: string[]) => void;
  route: any;
  routeType: 'multiple' | 'single' | 'fast' | undefined;
  readonly?: boolean;
  isWaiver: boolean;
}

const emailRegex = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
const pathString = pipe(split(/[[\].]/), path);

const PageDetail: React.FC<Props> = (props) => {
  const {
    onReceiverChange,
    readonly = true,
    route,
    routeType,
    isWaiver,
  } = props;

  const getField = (fieldPath: string) => pathString(fieldPath)(route);

  const getNameOrLabel = (fieldPath: string, altFieldPath = '') => {
    return (
      // @ts-ignore
      getField(fieldPath)?.name ||
      // @ts-ignore
      getField(fieldPath)?.label ||
      // @ts-ignore
      getField(altFieldPath)
    );
  };

  return (
    <PageForm className="route-detail" layout="vertical" submitter={false}>
      <Row gutter={24}>
        <Col span={24}>
          <Typography.Title level={5}>
            FLETE #<span>{route.customerOrder || ' Indefinido'}</span>
          </Typography.Title>
        </Col>

        <Col className="origin-destination" span={24}>
          <ProFormText
            initialValue={
              <div className="route-detail__origin-destination">
                {routeType !== 'fast' ? (
                  <div>{getNameOrLabel('origin', 'originLocation.name')}</div>
                ) : (
                  //@ts-ignore
                  <div>{getField('origin.address')}</div>
                )}
                <div className="route-detail_location-arrow">
                  <ArrowRightOutlined />
                </div>
                {routeType !== 'fast' ? (
                  <div>
                    {getNameOrLabel('destination', 'destinationLocation.name')}
                  </div>
                ) : (
                  //@ts-ignore
                  <div>{getField('destination.address')}</div>
                )}
              </div>
            }
            name={['originDestination']}
            label="Ruta"
            readonly={true}
          />
        </Col>

        <Col span={6}>
          <ProFormText
            initialValue={getNameOrLabel('shipmentType')}
            label="Relación de tipo"
            name={['shipmentType']}
            readonly={true}
          />
        </Col>

        <Col span={6}>
          <ProFormText
            initialValue={getNameOrLabel('shipmentModalType')}
            label="Tipo de Viaje"
            name={['shipmentModalType']}
            readonly={true}
          />
        </Col>

        <Col span={18}>
          <ProFormText
            initialValue={getField('goods')}
            label="Carga"
            name={['goods']}
            readonly={true}
          />
        </Col>
        <Col span={6}></Col>

        {routeType !== 'fast' && !isWaiver && (
          <>
            <Col span={6}>
              <ProFormText
                initialValue={getNameOrLabel(
                  'assignment.truck',
                  'assignment.accountTruck.name',
                )}
                label="ID de la unidad"
                name={['truck']}
                readonly={true}
              />
            </Col>

            <Col span={6}>
              <ProFormText
                initialValue={getNameOrLabel('assignment.trailer1')}
                label="ID de la caja"
                name={['trailer1']}
                readonly={true}
              />
            </Col>

            <Col span={6}>
              {!!getField('assignment.trailer2') && (
                <ProFormText
                  initialValue={getNameOrLabel('assignment.trailer2')}
                  label="ID de la caja (full)"
                  name={['trailer2']}
                  readonly={true}
                />
              )}
            </Col>

            <Col span={6} />

            <Col span={6}>
              <ProFormText
                initialValue={getNameOrLabel('', 'assignment.driver.label')}
                label="ID de la operador"
                name={['driver']}
                readonly={true}
              />
            </Col>
          </>
        )}

        <Col span={6}>
          <ProFormText
            initialValue={`${getField('weight')} KG`}
            label="Peso"
            name={['weight']}
            readonly={true}
          />
        </Col>

        <Col span={6}>
          <ProFormText
            initialValue={getField('routeCost')}
            label="Costo"
            name={['routeCost']}
            readonly={true}
          />
        </Col>

        {!isWaiver && (
          <Col span={6}>
            <ProFormText
              initialValue={getField('routeSale')}
              label="Venta"
              name={['routeSale']}
              readonly={true}
            />
          </Col>
        )}

        <Col span={16}>
          {!readonly && (
            <p>
              En caso de requerir cambios del estatus del embarque favor de
              agregar un correo electrónico
            </p>
          )}

          <ProFormSelect
            initialValue={getField('receivers')}
            label={readonly ? 'Estatus del embarque se enviara' : ''}
            name={['receivers']}
            fieldProps={{
              mode: 'tags',
              onChange: (value) => {
                onReceiverChange?.(value);
              },
            }}
            rules={[
              {
                validator: (rule, values, callback) => {
                  const invalidInputs = values.filter(
                    (value: string) => !value.match(emailRegex),
                  );
                  if (invalidInputs.length === 0) {
                    callback();
                  } else {
                    callback('Email no valido');
                  }
                },
              },
            ]}
            readonly={readonly}
          />
        </Col>
      </Row>
    </PageForm>
  );
};

export default PageDetail;
