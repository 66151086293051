import React from 'react';

import dayjs from 'dayjs';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import * as api from 'api';
import { Card, FormWrapper } from 'components';
import { useAccountUrl } from 'hooks';
import { Truck } from 'models';

import PageForm from './PageForm';

interface Props {
  onCancel?: () => void;
  onSuccess?: () => void;
}

const EditWrapper: React.FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;

  const { baseApiUrl } = useAccountUrl();
  const { truckId } = useParams<{ truckId: string }>();

  const { data: truck, isLoading } = useQuery(`getTruck__${truckId}`, () =>
    api.getTruck({
      meta: { baseUrl: baseApiUrl },
      params: { truckId: truckId },
    }),
  );
  const { mutateAsync } = useMutation(api.updateTruck);

  const handleFinish = async (values: Record<string, any>) => {
    if (!truck?.id) {
      return;
    }
    const data = values as Truck;
    (await mutateAsync({
      data: data,
      meta: { baseUrl: baseApiUrl },
      params: { truckId: truck.id },
    })) && onSuccess?.();
  };

  return (
    <Card bodyStyle={{ margin: 'auto', width: '80%' }} loading={isLoading}>
      <FormWrapper
        initialValues={{
          ...truck,
          circulationCardExpiration: dayjs(truck?.circulationCardExpiration),
          model: !!truck ? dayjs().year(truck.model) : undefined,
          odometer: Number(truck?.odometer || 0),
        }}
        layout="vertical"
        onFinish={handleFinish}
        onCancel={() => onCancel?.()}
      >
        <PageForm title="Editar unidad" />
      </FormWrapper>
    </Card>
  );
};

export default EditWrapper;
