import { fetch } from '../../lib';
import { AccountContact } from '../../models';

interface Variables {
  data: Omit<AccountContact, 'id' | 'isRepresentative'>;
  meta: { baseUrl: string };
  params: { accountContactId: number | string };
}

export const updateAccountContact = ({ data, meta, params }: Variables) => {
  const { accountJobPosition, accountLocation, ...rest } = data;
  return fetch.call(
    'PUT',
    `${meta.baseUrl}/contacts/${params.accountContactId}`,
    {
      ...rest,
      accountJobPositionId: accountJobPosition.id,
      accountLocationId: accountLocation.id,
    },
  );
};
