import React from 'react';

import { Card, Form } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import * as api from 'api';
import { FormWrapper } from 'components';
import { useAccountUrl } from 'hooks';
import { ShipmentNoteGlobal } from 'models';

import PageForm from './PageForm';

interface Props {
  onCancel?: () => void;
  onSuccess?: () => void;
}

const EditWrapper: React.FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;

  const { accountType, baseApiUrl } = useAccountUrl();
  const { shipmentNoteId } = useParams<{ shipmentNoteId: string }>();

  const [form] = Form.useForm();
  const { data: shipmentNote, isLoading } = useQuery(
    `getShipmentNote__${shipmentNoteId}`,
    () =>
      api.getShipmentNote({
        meta: { baseUrl: baseApiUrl },
        params: { shipmentNoteId: shipmentNoteId },
      }),
  );
  const { mutateAsync } = useMutation(api.updateShipmentNote);

  const handleFinish = async (values: Record<string, any>) => {
    if (!shipmentNote?.id) {
      return;
    }
    (await mutateAsync({
      data: {
        ...values,
        destinationCity: { id: values.destinationCity?.city?.value },
        originCity: { id: values.originCity?.city?.value },
      } as ShipmentNoteGlobal,
      meta: { baseUrl: baseApiUrl },
      params: {
        shipmentNoteId: shipmentNote.id,
      },
    })) && onSuccess?.();
  };

  return (
    <Card bodyStyle={{ margin: 'auto', width: '80%' }} loading={isLoading}>
      <FormWrapper
        form={form}
        initialValues={{
          ...shipmentNote,
          destinationCity: {
            city: {
              id: shipmentNote?.destinationCity?.id,
              label: shipmentNote?.destinationCity?.name,
              value: shipmentNote?.destinationCity?.id,
            },
            country: {
              id: shipmentNote?.destinationCity?.state?.country?.id,
              label: shipmentNote?.destinationCity?.state?.country?.name,
              value: shipmentNote?.destinationCity?.state?.country?.id,
            },
            state: {
              id: shipmentNote?.destinationCity?.state?.id,
              label: shipmentNote?.destinationCity?.state?.name,
              value: shipmentNote?.destinationCity?.state?.id,
            },
          },
          originCity: {
            city: {
              id: shipmentNote?.originCity?.id,
              label: shipmentNote?.originCity?.name,
              value: shipmentNote?.originCity?.id,
            },
            country: {
              id: shipmentNote?.originCity?.state?.country?.id,
              label: shipmentNote?.originCity?.state?.country?.name,
              value: shipmentNote?.originCity?.state?.country?.id,
            },
            state: {
              id: shipmentNote?.originCity?.state?.id,
              label: shipmentNote?.originCity?.state?.name,
              value: shipmentNote?.originCity?.state?.id,
            },
          },
        }}
        layout="vertical"
        onFinish={handleFinish}
        onCancel={() => onCancel?.()}
      >
        <PageForm
          accountType={accountType}
          initialData={shipmentNote}
          form={form}
          title="Editar nota de flete"
        />
      </FormWrapper>
    </Card>
  );
};

export default EditWrapper;
