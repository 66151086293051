import React from 'react';

import { Form, Select } from 'antd';
import { FormItemProps } from 'antd/es/form';
import { SelectProps } from 'antd/es/select';
import { useQuery, UseQueryOptions } from 'react-query';

import { fetch } from '../../lib';

interface SelectSearchProps<TOption> {
  formItemProps?: Partial<FormItemProps>;
  initialData?: TOption[];
  queryKey?: string;
  queryOptions?: UseQueryOptions<TOption[]>;
  queryParams?: Record<string, any>;
  renderOption: (option: TOption) => {};
  selectProps?: SelectProps<any>;
  url: string;
}

export function SelectQuery<TOption>(props: SelectSearchProps<TOption>) {
  const { formItemProps, initialData, queryKey, queryOptions } = props;
  const { queryParams, renderOption, selectProps, url } = props;

  const { data = [], status } = useQuery<TOption[]>(
    queryKey || `SELECT_QUERY__${url}`,
    () => fetch.call('GET', url, {}, { params: queryParams }),
    queryOptions,
  );

  const results = initialData && data ? [...initialData, ...data] : data;

  return (
    <Form.Item {...formItemProps}>
      <Select
        filterOption={false}
        {...selectProps}
        defaultActiveFirstOption={false}
        loading={status === 'loading'}
      >
        {(results && results?.map((option: TOption) => renderOption(option))) ||
          null}
      </Select>
    </Form.Item>
  );
}
