import React from 'react';

import { Layout } from 'antd';

import './PrivateLayout.css';

// import BreadcrumbRouter from '../../BreadcrumbRouter';
import Header from '../../Header';
import Sider from '../../Sider';

export const PrivateLayout: React.FC = (props) => {
  const { children } = props;

  return (
    <Layout className="private-layout__layout">
      <Header className="private-layout__header" />

      <Layout>
        <Sider />

        <Layout.Content className="private-layout__content">
          {/*<BreadcrumbRouter />*/}

          {children}
        </Layout.Content>
      </Layout>
      <Layout.Footer className="private-layout__footer" />
    </Layout>
  );
};
