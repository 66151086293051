import React from 'react';

import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, useHistory } from 'react-router-dom';

import { TableList, UniqueCollapsable } from 'components';
import { Account, ShipmentNoteCarrier, ShipmentNoteClient } from 'models';

interface Props {
  account: Account;
  baseUrl: string;
  active?: boolean;
}

type ShipmentNote = ShipmentNoteCarrier | ShipmentNoteClient;

const ShipmentNoteList: React.FC<Props> = (props) => {
  const { account, baseUrl, active } = props;

  const history = useHistory();

  const columns: ColumnsType<ShipmentNote> = [
    {
      dataIndex: ['originCity', 'name'],
      key: 'originCityAddress',
      render: (text, record) => (
        <Link to={`${baseUrl}/${account.id}/shipment-notes/${record.id}/edit`}>
          <span>{record.originCity.name}</span>
        </Link>
      ),
      title: 'Origen',
    },
    {
      dataIndex: ['destinationCity', 'name'],
      key: 'destinationCityAddress',
      title: 'Destino',
    },
    {
      dataIndex: ['cost'],
      key: 'cost',
      title: 'Costo',
    },
    {
      dataIndex: ['shipmentModalType', 'name'],
      key: 'shipmentModalTypeName',
      title: 'Tipo',
    },
    {
      dataIndex: ['truckType', 'name'],
      key: 'truckTypeName',
      title: 'Equipo',
    },
  ];

  const extra = (
    <Button
      onClick={() =>
        history.push(`${baseUrl}/${account.id}/shipment-notes/add`, {
          account: account,
          baseUrl: baseUrl,
        })
      }
      size="small"
    >
      Nuevo
    </Button>
  );

  return (
    <UniqueCollapsable extra={extra} header="Nota de fletes" active={active}>
      <TableList<ShipmentNote>
        columns={columns}
        historyPush={{
          state: { account },
          url: `${baseUrl}/${account.id}/shipment-notes`,
        }}
        url={`${baseUrl}/${account.id}/shipment-notes`}
      />
    </UniqueCollapsable>
  );
};

export default ShipmentNoteList;
