import { fetch } from '../../lib';
import { Truck } from '../../models';

interface Variables {
  meta: { baseUrl: string };
  params: { truckId: string };
}

export const getTruck = ({ meta, params }: Variables) => {
  const { truckId } = params;
  return fetch.call<Truck>('GET', `${meta.baseUrl}/trucks/${truckId}`);
};
