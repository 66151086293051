import React from 'react';

import { Button, Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import { Space, Typography } from 'antd';

import './RegisterForm.css';

import { Title } from '../../../components';

interface Props {
  disabled: boolean;
  initialValues?: any;
  isLoading?: boolean;
  onNextStep: (formData: any) => void;
  onPrevStep?: () => void;
  subTitles: React.ReactNode[];
  title: React.ReactNode;
}

export const RegisterForm: React.FC<Props> = (props) => {
  const { disabled, initialValues, isLoading, onNextStep, onPrevStep } = props;
  const { subTitles, title } = props;

  const handleFinish = (values: any) => {
    onNextStep(values);
  };

  return (
    <Row>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Title subTitles={subTitles} title={title} />
      </Col>

      <Col span={24}>
        <Form initialValues={initialValues} onFinish={handleFinish}>
          <Row className="register-form__row" gutter={12}>
            <Col span={24}>
              <Form.Item name={['name']} rules={[{ required: true }]}>
                <Input disabled={disabled} placeholder="Nombre comercial" />
              </Form.Item>
            </Col>

            <Col span={16}>
              <Form.Item name={['nameLegal']} rules={[{ required: true }]}>
                <Input disabled={disabled} placeholder="Nombre legal" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name={['rfc']} rules={[{ required: true }]}>
                <Input disabled={disabled} placeholder="RFC" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name={['firstName']} rules={[{ required: true }]}>
                <Input disabled={disabled} placeholder="Nombre representante" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['lastName']} rules={[{ required: true }]}>
                <Input
                  disabled={disabled}
                  placeholder="Apellidos representante"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name={['email']} rules={[{ required: true }]}>
                <Input disabled={disabled} placeholder="Email" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name={['phone']} rules={[{ required: true }]}>
                <Input disabled={disabled} placeholder="Teléfono particular" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name={['phoneMobile']} rules={[{ required: true }]}>
                <Input disabled={disabled} placeholder="Teléfono celular" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name={['agreement']}
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              'Debe aceptar los Términos y Condiciones',
                            ),
                          ),
                  },
                ]}
                valuePropName="checked"
              >
                <Checkbox disabled={disabled}>
                  Acepto los{' '}
                  <Typography.Link href="" target="_blank" underline={true}>
                    Términos y Condiciones
                  </Typography.Link>{' '}
                  para continuar
                </Checkbox>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Divider />
            </Col>

            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item>
                <Space>
                  {disabled && !!onPrevStep && (
                    <Button disabled={isLoading} onClick={() => onPrevStep()}>
                      Regresar
                    </Button>
                  )}
                  <Button htmlType="submit" loading={isLoading} type="primary">
                    {!initialValues ? 'Continuar' : 'Registrar'}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
