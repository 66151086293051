import { Account, CarrierConfig } from 'models';
import { Link, useHistory } from 'react-router-dom';
import { TableList, UniqueCollapsable } from 'components';

import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';

interface Props {
  account: Account;
  baseUrl: string;
  active?:boolean;
}

const CarrierConfigList: React.FC<Props> = (props) => {
  const { account, baseUrl ,active} = props;

  const history = useHistory();

  const columns: ColumnsType<CarrierConfig> = [
    {
      dataIndex: ['mirrorWebsite'],
      key: 'mirrorWebsite',
      render: (text, record) => (
        <Link to={`${baseUrl}/${account.id}/configs/${record.id}/edit`}>
          <span>{record.mirrorWebsite}</span>
        </Link>
      ),
      title: 'Pagina web',
    },
    {
      dataIndex: ['mirrorUser'],
      key: 'mirrorUser',
      title: 'Usuario',
    },
    {
      dataIndex: ['mirrorPassword'],
      key: 'mirrorPassword',
      title: 'Contraseña',
    },
  ];

  const extra = (
    <Button
      onClick={() =>
        history.push(`${baseUrl}/${account.id}/configs/add`, {
          account: account,
          baseUrl: baseUrl,
        })
      }
      size="small"
    >
      Nuevo
    </Button>
  );

  return (
    <UniqueCollapsable extra={extra} header="Configuración" active={active}>
      <TableList<CarrierConfig>
        columns={columns}
        historyPush={{
          state: { account },
          url: `${baseUrl}/${account.id}/configs`,
        }}
        url={`${baseUrl}/${account.id}/configs`}
      />
    </UniqueCollapsable>
  );
};

export default CarrierConfigList;
