import React, { useState } from 'react';

import RouteForm from './RouteForm';
import RouteTypeSelector from './RouteTypeSelector';

interface Props {
  onNextStep: (data: any) => void;
  routes: any[] | undefined;
  routeType: 'multiple' | 'single' | 'fast' | undefined;
  callback: Function;
  isWaiver: boolean;
}

const RouteStep: React.FC<Props> = (props) => {
  const {
    onNextStep,
    routes: _routes,
    routeType: _routeType,
    callback,
    isWaiver,
  } = props;

  const [editableRouteIndex, setEditableRouteIndex] = useState<
    number | undefined
  >(undefined);
  const [routes, setRoutes] = useState<any[]>(_routes || []);
  const [routeType, setRouteType] = useState<
    'multiple' | 'single' | 'fast' | undefined
  >(_routeType);

  if (editableRouteIndex === undefined) {
    if (routeType === undefined || !!routes.length) {
      return (
        <RouteTypeSelector
          onEditRoute={(index) => setEditableRouteIndex(index)}
          onNextStep={() => onNextStep(routes)}
          onTypeSelected={(type) => {
            setRouteType(type);
            callback(type);
          }}
          routes={routes}
          currRouteType={routeType}
          isWaiver={isWaiver}
        />
      );
    }
  }

  if (routeType !== undefined) {
    return (
      <RouteForm
        onCancel={() => {
          setEditableRouteIndex(undefined);
          if (!routes.length) {
            setRouteType(undefined);
          }
        }}
        onFinish={(routes) => {
          setEditableRouteIndex(undefined);
          setRoutes(routes);
        }}
        editableRouteIndex={editableRouteIndex}
        routes={routes}
        routeType={routeType}
        isWaiver={isWaiver}
      />
    );
  }

  return null;
};

export default RouteStep;
