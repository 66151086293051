import React, { useState, useEffect } from 'react';

import { Col, Form, Input, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';

import { Card, MapLookupLocation, SelectSearch } from 'components';
import { City, Country, LabelInValue, State } from 'models';

interface Props {
  form: FormInstance;
  initialAddress?: {
    city?: LabelInValue<City>;
    country?: LabelInValue<Country>;
    state?: LabelInValue<State>;
  };
  title: string;
}

const PageForm: React.FC<Props> = (props) => {
  const { form, initialAddress, title } = props;

  const [address, setAddress] = useState<string>('');
  const [position, setPosition] = useState<any>();

  useEffect(() => {
    const geomLocation = form.getFieldValue(['geomLocation']);

    if (geomLocation) {
      form.setFieldsValue({
        geomLocation,
        lat: geomLocation.lat,
        lng: geomLocation.lng,
      });

      setPosition(geomLocation);
    }
  }, [form]);

  const handleAddressChange = () => {
    const { getFieldValue } = form;

    const addressForm = getFieldValue(['address']);
    const { label: city = '' } = getFieldValue('city') || {};
    const { label: country = '' } = getFieldValue('country') || {};
    const { label: state = '' } = getFieldValue('state') || {};

    if (!!addressForm && !!city && !!country && !!state) {
      setAddress(`${addressForm} ${city} ${state} ${country}`);
    }
  };

  const handleUpdateLat = (lat?: string) => {
    const currentGeoLocation = form.getFieldValue('geomLocation');
    const newGeoLocation = { ...currentGeoLocation, lat: Number(lat) };

    form.setFieldsValue({
      geomLocation: newGeoLocation,
      lat,
    });
    setPosition(newGeoLocation);
  };

  const handleUpdateLng = (lng?: string) => {
    const currentGeoLocation = form.getFieldValue('geomLocation');
    const newGeoLocation = { ...currentGeoLocation, lng: Number(lng) };

    form.setFieldsValue({
      geomLocation: newGeoLocation,
      lng,
    });

    setPosition(newGeoLocation);
  };

  return (
    <React.Fragment>
      <Form.Item name={['geomLocation', 'lat']} label="" noStyle={true}>
        <Input disabled={true} style={{ display: 'none' }} />
      </Form.Item>

      <Form.Item name={['geomLocation', 'lng']} label="" noStyle={true}>
        <Input disabled={true} style={{ display: 'none' }} />
      </Form.Item>

      <Card.Title title={title} />

      <Row gutter={24}>
        <Card.Divider>Información general</Card.Divider>

        <Col span={12}>
          <Form.Item
            name={['name']}
            label="Nombre"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name={['address']}
            label="Dirección"
            rules={[{ required: true }]}
          >
            <Input onChange={() => handleAddressChange()} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <SelectSearch<LabelInValue<Country>>
            formItemProps={{
              name: ['country'],
              label: 'País',
              rules: [{ required: true }],
            }}
            initialData={
              !!initialAddress?.country?.id ? [initialAddress.country] : []
            }
            renderOption={(option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name || option.label}
              </Select.Option>
            )}
            selectProps={{
              labelInValue: true,
              onChange: () => {
                form.setFieldsValue({ city: undefined, state: undefined });
                handleAddressChange();
              },
            }}
            url="/catalogs/countries"
          />
        </Col>

        <Col span={6}>
          <Form.Item
            noStyle={true}
            shouldUpdate={(prev, curr) =>
              prev.country?.value !== curr.country?.value
            }
          >
            {({ getFieldValue }) => {
              const { value: country } = getFieldValue(['country']) || {};
              const hasCountry = !!country;

              return (
                <SelectSearch<LabelInValue<State>>
                  formItemProps={{
                    name: ['state'],
                    label: 'Estado',
                    rules: [{ required: true }],
                  }}
                  initialData={
                    !!initialAddress?.state ? [initialAddress.state] : []
                  }
                  queryOptions={{ enabled: hasCountry }}
                  queryParams={{ countryId: country }}
                  renderOption={(option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name || option.label}
                    </Select.Option>
                  )}
                  selectProps={{
                    disabled: !hasCountry,
                    labelInValue: true,
                    onChange: () => {
                      form.setFieldsValue({ city: undefined });
                      handleAddressChange();
                    },
                  }}
                  url="/catalogs/states"
                />
              );
            }}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            noStyle={true}
            shouldUpdate={(prev, curr) =>
              prev.state?.value !== curr.state?.value
            }
          >
            {({ getFieldValue }) => {
              const { value: state } = getFieldValue(['state']) || {};
              const hasState = !!state;

              return (
                <SelectSearch<LabelInValue<City>>
                  formItemProps={{
                    name: ['city'],
                    label: 'Ciudad',
                    rules: [{ required: true }],
                  }}
                  initialData={
                    !!initialAddress?.city ? [initialAddress.city] : []
                  }
                  queryOptions={{ enabled: hasState }}
                  queryParams={{ stateId: state }}
                  renderOption={(option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name || option.label}
                    </Select.Option>
                  )}
                  selectProps={{
                    disabled: !hasState,
                    labelInValue: true,
                    onChange: () => handleAddressChange(),
                  }}
                  url="/catalogs/cities"
                />
              );
            }}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name={['zipcode']}
            label="Código postal"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name={['phone']} label="Teléfono">
            <Input />
          </Form.Item>
        </Col>

        <Col span={24} style={{ marginBottom: 20, marginTop: 10 }}>
          <MapLookupLocation
            address={address}
            initialPosition={position}
            onChange={(latLng) => {
              form.setFieldsValue({
                geomLocation: latLng,
                lat: latLng.lat,
                lng: latLng.lng,
              });
            }}
          />
        </Col>
        <Col span={6}>
          <Form.Item name={['lat']} label="Latitud">
            <Input
              type="number"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleUpdateLat(e.currentTarget.value);
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={['lng']} label="Longitud">
            <Input
              type="number"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleUpdateLng(e.currentTarget.value);
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PageForm;
