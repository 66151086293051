import React, { createContext } from 'react';

import { useAccountProvider } from 'hooks';
import { Account } from 'models';

interface IContext {
  account?: Account;
  baseUrl: string;
}

export const AccountContext = createContext<IContext>({} as IContext);

export const AccountProvider: React.FC = (props) => {
  const accountProvider = useAccountProvider();
  return (
    <AccountContext.Provider value={accountProvider}>
      {props.children}
    </AccountContext.Provider>
  );
};
