import { fetch } from 'lib';
import { ID, ShipmentNoteGlobal } from 'models';

interface Variables {
  data: Omit<ShipmentNoteGlobal, 'id'>;
  meta: { baseUrl: string };
  params: { shipmentNoteId: ID };
}

export const updateShipmentNote = ({ data, meta, params }: Variables) => {
  const { cost, destinationCity, isReturnedEmpty, originCity } = data;
  const { shipmentModalType, truckType } = data;

  return fetch.call(
    'PUT',
    `${meta.baseUrl}/shipment-notes/${params.shipmentNoteId}`,
    {
      cost: cost,
      destinationCityId: destinationCity.id,
      isReturnedEmpty: Boolean(isReturnedEmpty),
      originCityId: originCity.id,
      shipmentModalTypeId: shipmentModalType.id,
      truckTypeId: truckType.id,
    },
  );
};
