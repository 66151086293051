import React from 'react';

import { Col, Divider as AntDivider } from 'antd';

import './Divider.css';

interface Props {
  children?: React.ReactNode;
}

export const Divider: React.FC<Props> = (props) => (
  <Col span={24}>
    <AntDivider className="card-divider__divider" orientation="left">
      {props.children}
    </AntDivider>
  </Col>
);
