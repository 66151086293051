import React from 'react';

import { Card, Form } from 'antd';
import { useMutation } from 'react-query';

import * as api from 'api';
import { FormWrapper } from 'components';
import { useAccountUrl } from 'hooks';
import { AccountLocation } from 'models';

import PageForm from './PageForm';

interface Props {
  onCancel?: () => void;
  onSuccess?: () => void;
}

const AddWrapper: React.FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;

  const urls = useAccountUrl();
  const [form] = Form.useForm();
  const { mutateAsync } = useMutation(api.addAccountLocation);

  const handleFinish = async (values: Record<string, any>) => {
    (await mutateAsync({
      data: { ...values, account: { id: urls.accountId } } as AccountLocation,
      meta: { baseUrl: urls.baseApiUrl },
    })) && onSuccess?.();
  };

  return (
    <Card bodyStyle={{ margin: 'auto', width: '80%' }}>
      <FormWrapper
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onCancel={() => onCancel?.()}
      >
        <PageForm form={form} title="Agregar ubicación" />
      </FormWrapper>
    </Card>
  );
};

export default AddWrapper;
