import { useLocation } from 'react-router-dom';
import { useAuth } from './useAuth';

interface Data {
  accountId?: number | string | undefined;
  accountType: 'carrier' | 'client' | 'owner';
  baseApiUrl: string;
  baseUrl: string;
}

export const useAccountUrl = (): Data => {
  const { pathname } = useLocation();
  const { user } = useAuth();

  const paths = pathname.split('/').slice(1);

  if (paths[0] === 'accounts') {
    return {
      accountId: paths[2],
      accountType: paths[1] === 'carriers' ? 'carrier' : 'client',
      baseApiUrl: `/accounts/${paths[1]}/${paths[2]}`,
      baseUrl: `/accounts/${paths[1]}/${paths[2]}`,
    };
  }

  return {
    accountId: user?.account?.id,
    accountType: 'owner',
    baseApiUrl: '/accounts',
    baseUrl: '/settings',
  };
};
