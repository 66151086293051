import React from 'react';

import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import * as api from 'api';
import { useAccountUrl } from 'hooks';
import { AccountContact } from 'models';
import { Card, FormWrapper } from 'components';

import PageForm from './PageForm';

interface Props {
  onCancel?: () => void;
  onSuccess?: () => void;
}

const EditWrapper: React.FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;

  const { baseApiUrl } = useAccountUrl();
  const { accountContactId } = useParams<{ accountContactId: string }>();

  const { data: accountContact, isLoading } = useQuery(
    `getAccountContact__${accountContactId}`,
    () =>
      api.getAccountContact({
        meta: { baseUrl: baseApiUrl },
        params: { accountContactId: accountContactId },
      }),
  );
  const { mutateAsync } = useMutation(api.updateAccountContact);

  const handleFinish = async (values: Record<string, any>) => {
    if (!accountContact?.id) {
      return;
    }

    const data = values as AccountContact;
    (await mutateAsync({
      data: data,
      meta: { baseUrl: baseApiUrl },
      params: {
        accountContactId: accountContact.id,
      },
    })) && onSuccess?.();
  };

  return (
    <Card bodyStyle={{ margin: 'auto', width: '80%' }} loading={isLoading}>
      <FormWrapper
        initialValues={accountContact}
        layout="vertical"
        onFinish={handleFinish}
        onCancel={() => onCancel?.()}
      >
        <PageForm title="Editar contacto" />
      </FormWrapper>
    </Card>
  );
};

export default EditWrapper;
