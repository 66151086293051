import React, { Suspense, useEffect } from 'react';

import { Skeleton } from 'antd';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { AccountRegister } from './AccountRegister';
import { Login } from './Login';

import { Layouts, PrivateRoute } from '../components';
import { useAuth } from '../hooks';
import { session } from '../lib';
import { getConfig } from '../routes';

export const App = () => {
  const auth = useAuth();
  const access = session.getSession('access');

  useEffect(() => {
    if (access) {
      if (auth.user === undefined && !auth.loading) {
        auth.loginByToken().then(() => null);
      }
    }
  }, [auth, access]);

  if ((auth.loading || auth.user === undefined) && access) {
    return <Skeleton />;
  }

  if (!auth.user) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/register">
            <AccountRegister />
          </Route>

          <Route path="/login">
            <Login />
          </Route>

          <Route path="*">
            <Redirect to="/login" />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }

  const { routes } = getConfig(auth.user);

  return (
    <BrowserRouter>
      <Layouts.PrivateLayout>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {routes
              .filter((route) => route.component)
              .map((route) => (
                <PrivateRoute
                  component={route.component}
                  key={route.path}
                  path={route.path}
                />
              ))}

            <Route path="*">Not Found</Route>
          </Switch>
        </Suspense>
      </Layouts.PrivateLayout>
    </BrowserRouter>
  );
};
