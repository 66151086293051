import React from 'react';

import { DeleteFilled, UserAddOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, useHistory } from 'react-router-dom';

import { Card, Table } from 'components';
import { useAccountUrl } from 'hooks';
import { Driver } from 'models';

const List = () => {
  const urls = useAccountUrl();
  const history = useHistory();

  const baseApiUrl = `${urls.baseApiUrl}/drivers`;
  const baseUrl = `${urls.baseUrl}/drivers`;

  const actions = [
    <Button
      icon={<UserAddOutlined />}
      key="add"
      onClick={() => history.push(`${baseUrl}/add`)}
      type="primary"
    >
      Agregar operador
    </Button>,
  ];

  const columns: ColumnsType<Driver> = [
    {
      dataIndex: ['firstName'],
      key: 'firstName',
      render: (text, record) => (
        <Link to={`${baseUrl}/${record.id}/edit`}>
          <span>{record.firstName}</span>
        </Link>
      ),
      title: 'Nombre',
    },
    {
      dataIndex: ['lastName'],
      key: 'lastName',
      title: 'Apellido',
    },
    {
      dataIndex: ['email'],
      key: 'email',
      title: 'Email',
    },
    {
      dataIndex: ['phone'],
      key: 'phone',
      title: 'Teléfono',
    },
    {
      dataIndex: ['phoneMobile'],
      key: 'phoneMobile',
      title: 'Celular',
    },
    {
      align: 'center',
      key: 'actions',
      render: (text, record) => {
        return (
          <Space>
            <DeleteFilled style={{ color: '#f56969' }} />
          </Space>
        );
      },
    },
  ];

  return (
    <Card>
      <Card.Title subTitle="Listado de operadores" title="Operadores" />

      <Table<Driver> actions={actions} columns={columns} url={baseApiUrl} />
    </Card>
  );
};

export default List;
