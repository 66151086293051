import AddWrapper from './AddWrapper';
import EditWrapper from './EditWrapper';
import FormOnly from './PageForm';

const PageForm = {
  AddWrapper,
  EditWrapper,
  FormOnly,
};

export default PageForm;
