import { fetch } from '../../lib';
import { Truck } from '../../models';

interface Variables {
  data: Truck;
  meta: { baseUrl: string };
  params: { truckId: number | string };
}

export const updateTruck = (_: Variables) => {
  const { data, meta, params } = _;

  const { accountCarrierConfig, accountLocation, accountOwnership } = data;
  const { truckStatus, truckType } = data;

  return fetch.call('PUT', `${meta.baseUrl}/trucks/${params.truckId}`, {
    ...data,
    accountCarrierConfigId: accountCarrierConfig.id,
    accountLocationId: accountLocation.id,
    accountOwnershipId: accountOwnership.id,
    model: new Date(data.model).getFullYear(),
    truckStatusId: truckStatus.id,
    truckTypeId: truckType.id,
  });
};
