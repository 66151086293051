import React from 'react';

import { Card as AntCard } from 'antd';
import { CardProps } from 'antd/es/card';

import { Divider } from './Divider';
import { ITitle, Title } from './Title';

interface CardComponent extends React.FC<CardProps> {
  Divider: React.ElementType;
  Title: React.ElementType<ITitle>;
}

const Card: CardComponent = (props) => {
  return (
    <AntCard {...props} style={{ margin: 25 }}>
      {props.children}
    </AntCard>
  );
};

Card.Divider = Divider;
Card.Title = Title;

export { Card };
