import React from 'react';

import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, useHistory } from 'react-router-dom';

import { TableList, UniqueCollapsable } from 'components';
import { Account, AccountContact } from 'models';

interface Props {
  account: Account;
  baseUrl: string;
  active?: boolean;
}

const ContactList: React.FC<Props> = (props) => {
  const { account, baseUrl, active } = props;

  const history = useHistory();

  const columns: ColumnsType<AccountContact> = [
    {
      dataIndex: ['firstName'],
      key: 'firstName',
      render: (text, record) => (
        <Link to={`${baseUrl}/${account.id}/contacts/${record.id}/edit`}>
          <span>{record.firstName}</span>
        </Link>
      ),
      title: 'Nombre',
    },
    {
      dataIndex: ['lastName'],
      key: 'lastName',
      title: 'Apellido',
    },
    {
      dataIndex: ['email'],
      key: 'email',
      title: 'Email',
    },
    {
      dataIndex: ['phone'],
      key: 'phone',
      title: 'Teléfono',
    },
    {
      dataIndex: ['phoneMobile'],
      key: 'phoneMobile',
      title: 'Celular',
    },
    {
      dataIndex: ['accountJobPosition', 'name'],
      key: 'accountJobPositionName',
      title: 'Cargo',
    },
    {
      dataIndex: ['accountLocation', 'name'],
      key: 'accountLocationName',
      title: 'Base operativa',
    },
  ];

  const extra = (
    <Button
      onClick={() =>
        history.push(`${baseUrl}/${account.id}/contacts/add`, {
          account: account,
          baseUrl: baseUrl,
        })
      }
      size="small"
    >
      Nuevo
    </Button>
  );

  return (
    <UniqueCollapsable extra={extra} header="Contactos" active={active}>
      <TableList<AccountContact>
        columns={columns}
        historyPush={{
          state: { account },
          url: `${baseUrl}/${account.id}/contacts`,
        }}
        url={`${baseUrl}/${account.id}/contacts`}
      />
    </UniqueCollapsable>
  );
};

export default ContactList;
