import React, { useState } from 'react';

import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from 'hooks';
import { MenuRoute } from 'models';
import { getConfig } from 'routes';

export const Sider: React.FC = () => {
  const { user } = useAuth();

  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);

  const menus: MenuRoute[] = getConfig(user).menus;

  const renderMenu = (menu: MenuRoute) => (
    <Menu.Item icon={menu.icon} key={menu.path}>
      <Link to={menu.path}>{menu.title}</Link>
    </Menu.Item>
  );

  const renderSubMenu = (menu: MenuRoute) => {
    return (
      <Menu.SubMenu key={menu.path} icon={menu.icon} title={menu.title}>
        {menu.subMenus &&
          menu.subMenus.map((subMenu) => {
            if (!!subMenu.subMenus?.length) {
              return renderSubMenu(subMenu);
            }
            return renderMenu(subMenu);
          })}
      </Menu.SubMenu>
    );
  };

  return (
    <Layout.Sider
      collapsed={collapsed}
      collapsedWidth={50}
      onMouseEnter={() => collapsed && setCollapsed(false)}
      onMouseLeave={() => !collapsed && setCollapsed(true)}
      theme="light"
      width={225}
    >
      <Menu
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
        theme="light"
      >
        {menus.map((menu) => {
          if (menu.subMenus) {
            return renderSubMenu(menu);
          }
          return renderMenu(menu);
        })}
      </Menu>
    </Layout.Sider>
  );
};
