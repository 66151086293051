import { fetch } from 'lib';
import { Driver } from 'models';

interface Variables {
  data: Driver;
  meta: { baseUrl: string };
  params: { driverId: number | string };
}

export const updateDriver = ({ data, meta, params }: Variables) => {
  const { accountContact: _ac, driverLicenseType, ...rest } = data;
  const { id: accountContactId, ...accountContact } = _ac;
  return fetch.call('PUT', `${meta.baseUrl}/drivers/${params.driverId}`, {
    ...rest,
    ...accountContact,
    accountContactId: accountContactId,
    driverLicenseTypeId: driverLicenseType.id,
  });
};
