import React from 'react';

import { useMutation } from 'react-query';

import * as api from 'api';
import { Card, FormWrapper } from 'components';
import { Account } from 'models';

import PageForm from './PageForm';

interface Props {
  baseUrl: string;
  onCancel?: () => void;
  onSuccess?: () => void;
  title: string;
}

const AddWrapper: React.FC<Props> = (props) => {
  const { baseUrl, onCancel, onSuccess, title } = props;

  const { mutateAsync } = useMutation(api.addAccount);

  const handleFinish = async (values: Record<string, any>) => {
    const data = values as Account;
    (await mutateAsync({ data, meta: { baseUrl } })) && onSuccess?.();
  };

  return (
    <Card bodyStyle={{ margin: 'auto', width: '80%' }}>
      <FormWrapper
        layout="vertical"
        onFinish={handleFinish}
        onCancel={() => onCancel?.()}
      >
        <PageForm title={title} />
      </FormWrapper>
    </Card>
  );
};

export default AddWrapper;
