import React from 'react';

import { DeleteFilled, UserAddOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useHistory } from 'react-router';

import { Card, Table } from 'components';
import { Account } from 'models';

interface Props {
  baseUrl: string;
  buttonText: string;
  subTitle: string;
  title: string;
}

function List<T extends Account>(props: Props) {
  const { baseUrl, buttonText, subTitle, title } = props;

  const history = useHistory();

  const actions = [
    <Button
      icon={<UserAddOutlined />}
      key="add"
      onClick={() => history.push(`${baseUrl}/add`)}
      type="primary"
    >
      {buttonText}
    </Button>,
  ];

  const columns: ColumnsType<T> = [
    {
      key: 'name',
      render: (text, record) => (
        <Typography.Link
          onClick={(event) => {
            event.preventDefault();
            history.push(`${baseUrl}/${record.id}/dashboard`, {
              account: record,
            });
          }}
        >
          {record.name}
        </Typography.Link>
      ),
      title: 'Nombre comercial',
    },
    {
      dataIndex: ['nameLegal'],
      key: 'nameLegal',
      title: 'Nombre legal',
    },
    {
      dataIndex: ['rfc'],
      key: 'rfc',
      title: 'RFC',
    },
    {
      dataIndex: ['contact', 'email'],
      key: 'contactEmail',
      title: 'Email',
    },
    {
      dataIndex: ['contact', 'phone'],
      key: 'contactPhone',
      title: 'Teléfono',
    },
    {
      dataIndex: ['contact', 'phoneMobile'],
      key: 'contactPhoneMobile',
      title: 'Celular',
    },
    {
      align: 'center',
      key: 'actions',
      render: (text, record) => {
        return (
          <Space>
            <DeleteFilled style={{ color: '#f56969' }} />
          </Space>
        );
      },
    },
  ];

  return (
    <Card>
      <Card.Title subTitle={subTitle} title={title} />
      <Table<T> actions={actions} columns={columns} url={baseUrl} />
    </Card>
  );
}

export default List;
