import React from 'react';

import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, useHistory } from 'react-router-dom';

import { Card, Table } from 'components';
import { useAccountUrl } from 'hooks';
import { AccountLocation } from 'models';

const List = () => {
  const urls = useAccountUrl();
  const history = useHistory();

  const baseApiUrl = `${urls.baseApiUrl}/locations`;
  const baseUrl = `${urls.baseUrl}/locations`;

  const actions = [
    <Button
      icon={<PlusOutlined />}
      key="add"
      onClick={() => history.push(`${baseUrl}/add`)}
      type="primary"
    >
      Agregar ubicación
    </Button>,
  ];

  const columns: ColumnsType<AccountLocation> = [
    {
      dataIndex: ['name'],
      key: 'name',
      render: (text, record) => (
        <Link to={`${baseUrl}/${record.id}/edit`}>
          <span>{record.name}</span>
        </Link>
      ),
      title: 'Nombre',
    },
    {
      dataIndex: ['address'],
      render: (text, record) =>
        [
          record.address,
          record.zipcode,
          ',',
          record?.city?.name,
          record?.city?.state?.name,
          record?.city?.state?.country?.name,
        ].join(' '),
      title: 'Dirección',
    },
    {
      align: 'center',
      key: 'actions',
      render: () => {
        return (
          <Space>
            <DeleteFilled style={{ color: '#f56969' }} />
          </Space>
        );
      },
    },
  ];

  return (
    <Card>
      <Card.Title
        subTitle="Favor de agregar las ubicaciones para la cuenta"
        title="Ubicaciones"
      />

      <Table<AccountLocation>
        actions={actions}
        columns={columns}
        url={baseApiUrl}
      />
    </Card>
  );
};

export default List;
