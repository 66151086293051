import React from 'react';

import { ProFormDigit, ProFormText } from '@ant-design/pro-form';
import { Col, Form, Row, Select } from 'antd';

import { Card, DatePicker, SelectQuery } from 'components';
import { useAccountUrl } from 'hooks';
import { AccountLocation, AccountOwnership, CarrierConfig } from 'models';
import { CarrierConfigUnit, TruckStatus, TruckType } from 'models';

interface Props {
  readonly?: boolean;
  title?: string;
}

const PageForm: React.FC<Props> = (props) => {
  const { readonly = false, title } = props;

  const urls = useAccountUrl();
  const { accountId, baseApiUrl } = urls;

  return (
    <Row gutter={24}>
      {title && <Card.Title title={title} />}

      <Card.Divider>Descripción de la unidad</Card.Divider>

      <Col span={8}>
        <ProFormText
          name={['name']}
          label="Número económico"
          rules={[{ required: true }]}
          readonly={readonly}
        />
      </Col>

      <Col span={8}>
        <ProFormText
          name={['plates']}
          label="Placas"
          rules={[{ required: true }]}
        />
      </Col>

      <Col span={8}>
        <SelectQuery<TruckType>
          formItemProps={{
            name: ['truckType', 'id'],
            label: 'Tipo de unidad',
            rules: [{ required: true }],
          }}
          renderOption={(option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          )}
          url="/catalogs/truck-type"
        />
      </Col>

      <Col span={8}>
        <Form.Item label="Modelo" name={['model']} rules={[{ required: true }]}>
          <DatePicker picker="year" style={{ width: '100%' }} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <ProFormText label="Color" name={['colour']} />
      </Col>

      <Col span={8}>
        <ProFormText label="Marca" name={['brand']} />
      </Col>

      <Col span={8}>
        <ProFormText
          label="Número de serie"
          name={['vin']}
          rules={[{ required: true }]}
        />
      </Col>
      <Col span={8}>
        <SelectQuery<AccountLocation>
          formItemProps={{
            name: ['accountLocation', 'id'],
            label: '¿A que ubicación pertenece?',
            rules: [{ required: true }],
          }}
          renderOption={(option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          )}
          url={`${baseApiUrl}/locations`}
        />
      </Col>

      <Col span={8}>
        <SelectQuery<AccountOwnership>
          formItemProps={{
            name: ['accountOwnership', 'id'],
            label: 'Tipo de propiedad',
            rules: [{ required: true }],
          }}
          renderOption={(option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          )}
          url="/catalogs/account-ownership"
        />
      </Col>

      <Col span={8}>
        <SelectQuery<TruckStatus>
          formItemProps={{
            name: ['truckStatus', 'id'],
            label: 'Estatus de unidad',
            rules: [{ required: true }],
          }}
          renderOption={(option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          )}
          url="/catalogs/truck-status"
        />
      </Col>

      <Card.Divider>Rendimiento</Card.Divider>

      <Col span={8}>
        <ProFormDigit label="Kilómetros por litro" name={['performance']} />
      </Col>

      <Col span={8}>
        <ProFormDigit label="Odómetro actual" name={['odometer']} />
      </Col>

      <Card.Divider>Tarjeta de circulación</Card.Divider>

      <Col span={8}>
        <ProFormText
          label="Num. de Tarjeta de circulación"
          name={['circulationCard']}
          rules={[{ required: true }]}
        />
      </Col>

      <Col span={8}>
        <Form.Item
          label="Expiración"
          name={['circulationCardExpiration']}
          rules={[{ required: true }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>

      <Card.Divider>Enlace cuenta espejo</Card.Divider>

      <Col span={8}>
        <SelectQuery<CarrierConfig>
          formItemProps={{
            name: ['accountCarrierConfig', 'id'],
            label: 'Cuenta espejo',
          }}
          renderOption={(option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.mirrorWebsite}
            </Select.Option>
          )}
          url={`/accounts/carriers/${accountId}/configs`}
        />
      </Col>

      <Col span={8}>
        <Form.Item
          noStyle={true}
          shouldUpdate={(prevValues, nextValues) =>
            prevValues?.accountCarrierConfig?.id !==
            nextValues?.accountCarrierConfig?.id
          }
        >
          {({ getFieldValue }) => {
            const accountCarrierConfigId = getFieldValue([
              'accountCarrierConfig',
              'id',
            ]);

            const url =
              `/accounts/carriers/${accountId}` +
              `/configs/${accountCarrierConfigId}/units`;

            return (
              <SelectQuery<CarrierConfigUnit>
                formItemProps={{
                  name: ['scraperUnit'],
                  label: 'Unidad cuenta espejo',
                }}
                queryOptions={{
                  enabled: !!accountCarrierConfigId,
                }}
                renderOption={(option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                )}
                selectProps={{
                  disabled: !accountCarrierConfigId,
                }}
                url={url}
              />
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default PageForm;
