import { AxiosRequestConfig } from 'axios';
import { fetch } from 'lib';

interface Variables {
  data: { values: any; config: AxiosRequestConfig };
}

export const addEvidence = ({ data }: Variables) => {
  const { values, config } = data;
  const { cartaporte, signature, evidences, comments, routeId } = values;

  const cp =
    cartaporte?.file?.originFileObj || cartaporte?.filename || undefined;
  const sig =
    signature?.file?.originFileObj || signature?.filename || undefined;

  const files = new FormData();

  if (evidences.fileList) {
    evidences.fileList.forEach((e: any) => {
      files.append(
        'evidences',
        e.originFileObj ||
          e.url.replace(`${process.env.REACT_APP_API_URL}/evidences/`, ''),
      );
    });
  } else {
    evidences.forEach((e: any) => {
      files.append('evidences', e.filename);
    });
  }
  files.append('cartaporte', cp);
  files.append('signature', sig);

  files.append('comments', comments);
  files.append('routeId', routeId);
  return fetch.call('POST', 'accounts/shipments/evidence', files, config);
};
