import React from 'react';

import { useMutation } from 'react-query';

import * as api from 'api';
import { Card, FormWrapper } from 'components';
import { useAccountUrl } from 'hooks';
import { Truck } from 'models';

import PageForm from './PageForm';

interface Props {
  onCancel?: () => void;
  onSuccess?: () => void;
}

const AddWrapper: React.FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;

  const urls = useAccountUrl();
  const { mutateAsync } = useMutation(api.addTruck);

  const handleFinish = async (values: Record<string, any>) => {
    const data = values as Truck;
    (await mutateAsync({ data: data, meta: { baseUrl: urls.baseApiUrl } })) &&
      onSuccess?.();
  };

  return (
    <Card bodyStyle={{ margin: 'auto', width: '80%' }}>
      <FormWrapper
        layout="vertical"
        onFinish={handleFinish}
        onCancel={() => onCancel?.()}
      >
        <PageForm title="Agregar unidad" />
      </FormWrapper>
    </Card>
  );
};

export default AddWrapper;
