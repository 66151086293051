import { fetch } from 'lib';
import { ShipmentNoteGlobal } from 'models';

interface Variables {
  data: Omit<ShipmentNoteGlobal, 'id'>;
  meta: { baseUrl: string };
}

export const addShipmentNote = ({ data, meta }: Variables) => {
  const { cost, destinationCity, isReturnedEmpty, originCity } = data;
  const { shipmentModalType, truckType } = data;

  return fetch.call('POST', `${meta.baseUrl}/shipment-notes`, {
    cost: cost,
    destinationCityId: destinationCity.id,
    isReturnedEmpty: Boolean(isReturnedEmpty),
    originCityId: originCity.id,
    shipmentModalTypeId: shipmentModalType.id,
    truckTypeId: truckType.id,
  });
};
