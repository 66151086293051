import React from 'react';

import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, useHistory } from 'react-router-dom';

import { TableList, UniqueCollapsable } from 'components';
import { Account, AccountLocation } from 'models';

interface Props {
  account: Account;
  baseUrl: string;
  active?: boolean;
}

export const LocationList: React.FC<Props> = (props) => {
  const { account, baseUrl, active } = props;

  const history = useHistory();

  const columns: ColumnsType<AccountLocation> = [
    {
      key: 'name',
      render: (text, record) => {
        return (
          <Link to={`${baseUrl}/${account.id}/locations/${record.id}/edit`}>
            {record.name}
          </Link>
        );
      },
      title: 'Nombre',
    },
    {
      key: 'address',
      render: (text, record) =>
        [
          record.address,
          record.zipcode,
          ',',
          record?.city?.name,
          record?.city?.state?.name,
          record?.city?.state?.country?.name,
        ].join(' '),
      title: 'Dirección',
    },
  ];

  const extra = (
    <Button
      onClick={() =>
        history.push(`${baseUrl}/${account.id}/locations/add`, { account })
      }
      size="small"
    >
      Nuevo
    </Button>
  );

  return (
    <UniqueCollapsable extra={extra} header="Ubicaciones" active={active}>
      <TableList<AccountLocation>
        columns={columns}
        historyPush={{
          state: { account },
          url: `${baseUrl}/${account.id}/locations`,
        }}
        url={`${baseUrl}/${account.id}/locations`}
      />
    </UniqueCollapsable>
  );
};

export default LocationList;
