import { fetch } from 'lib';
import { Truck } from 'models';

interface Variables {
  data: Truck;
  meta: { baseUrl: string };
}

export const addTruck = ({ data, meta }: Variables) => {
  const { accountCarrierConfig, accountLocation, accountOwnership } = data;
  const { truckStatus, truckType } = data;

  return fetch.call('POST', `${meta.baseUrl}/trucks`, {
    ...data,
    accountCarrierConfigId: accountCarrierConfig.id,
    accountLocationId: accountLocation.id,
    accountOwnershipId: accountOwnership.id,
    model: new Date(data.model).getFullYear(),
    truckStatusId: truckStatus.id,
    truckTypeId: truckType.id,
  });
};
