import { fetch } from 'lib';
import { Account } from 'models';

interface Variables {
  data: Omit<Account, 'id'>;
  meta: { baseUrl: string };
}

export const addAccount = ({ data, meta }: Variables) => {
  const { contact, ...rest } = data;
  return fetch.call('POST', meta.baseUrl, {
    ...contact,
    ...rest,
  });
};
