import CarrierConfigList from './CarrierConfigList';
import ContactList from './ContactList';
import DriverList from './DriverList';
import Information from './Information';
import LocationList from './LocationList';
import RouteList from './RouteList';
import ShipmentNoteList from './ShipmentNoteList';
import Title from './Title';
import TruckList from './TruckList';

const AccountDashboard = {
  CarrierConfigList,
  ContactList,
  DriverList,
  Information,
  LocationList,
  RouteList,
  ShipmentNoteList,
  Title,
  TruckList,
};

export default AccountDashboard;
