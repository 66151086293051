import { fetch } from 'lib';
import { Driver } from 'models';

interface Variables {
  data: Driver;
  meta: { baseUrl: string };
}

export const addDriver = ({ data, meta }: Variables) => {
  const { accountContact, driverLicenseType, ...rest } = data;

  let postData = {
    ...rest,
    driverLicenseTypeId: driverLicenseType.id,
  };

  if (!!accountContact.id) {
    // @ts-ignore
    postData = { ...postData, accountContactId: accountContact.id };
  } else {
    postData = { ...postData, ...accountContact };
  }

  return fetch.call('POST', `${meta.baseUrl}/drivers`, postData);
};
