import { useState } from 'react';

import * as api from '../api';
import { session } from '../lib';
import { AccountUser } from '../models';

export const useAuthProvider = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<AccountUser | null | undefined>(undefined);

  const login = (
    credentials: { email: string; password: string },
    cb: () => void,
  ) => {
    setLoading(true);

    return api
      .login({ data: credentials })
      .then(({ token, user }) => {
        setUser(user);
        session.setSession('access', token);
        cb();
      })
      .finally(() => setLoading(false));
  };

  const loginByToken = () => {
    setLoading(true);

    return api
      .getMe()
      .then((user) => setUser(user))
      .catch(() => setUser(null))
      .finally(() => setLoading(false));
  };

  const logout = (cb: () => void) => {};

  return { loading, login, loginByToken, logout, user };
};
