import React from 'react';

import { Typography } from 'antd';

import { Account } from 'models';

interface Props {
  account: Account;
}

const Title: React.FC<Props> = (props) => (
  <Typography>
    <Typography.Title level={3}>{props.account.name}</Typography.Title>
  </Typography>
);

export default Title;
