import React from 'react';

import './PublicLayout.css';

import { Layout } from 'antd';

export const PublicLayout: React.FC = (props) => {
  const { children } = props;

  return (
    <Layout className="public-layout__layout">
      <Layout.Header className="public-layout__header" />
      <Layout.Content className="public-layout__content">
        {children}
      </Layout.Content>
      <Layout.Footer className="public-layout__footer" />
    </Layout>
  );
};
