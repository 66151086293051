import React from 'react';

import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, useHistory } from 'react-router-dom';

// import { Card, Icons, Table } from 'components';
import { Card, Table } from 'components';
import { useAccountUrl } from 'hooks';
import { ShipmentNoteGlobal } from 'models';

const List = () => {
  const { accountType, baseApiUrl: bau, baseUrl: bu } = useAccountUrl();
  const history = useHistory();

  const baseApiUrl = `${bau}/shipment-notes`;
  const baseUrl = `${bu}/shipment-notes`;

  const actions = [
    // <Button
    //   icon={<Icons.Excel />}
    //   key="add"
    //   onClick={() => history.push(`${baseUrl}/add`)}
    //   type="primary"
    // >
    //   Importar desde Excel
    // </Button>,
    <Button
      icon={<PlusOutlined />}
      key="add"
      onClick={() => history.push(`${baseUrl}/add`)}
      type="primary"
    >
      Agregar Nota de flete
    </Button>,
  ];

  const getColumns = (): ColumnsType<ShipmentNoteGlobal> => {
    const columns: ColumnsType<ShipmentNoteGlobal> = [
      {
        key: 'originCityAddress',
        render: (text, record) => (
          <Link to={`${baseUrl}/${record.id}/edit`}>
            <span>{record.originCity.name}</span>
          </Link>
        ),
        title: 'Origen',
      },
      {
        dataIndex: ['destinationCity', 'name'],
        key: 'destinationCityName',
        title: 'Destino',
      },
      {
        dataIndex: ['cost'],
        key: 'cost',
        title: 'Costo',
      },
      {
        dataIndex: ['shipmentModalType', 'name'],
        key: 'shipmentModalTypeName',
        title: 'Tipo',
      },
      {
        dataIndex: ['truckType', 'name'],
        key: 'truckTypeName',
        title: 'Equipo',
      },
    ];

    if (accountType === 'carrier') {
      columns.push({
        key: 'isReturnedEmpty',
        render: (text, record) => (
          <Checkbox checked={record.isReturnedEmpty} disabled={true} />
        ),
        title: 'Regreso vacío',
        width: 125,
      });
    }

    columns.push({
      align: 'center',
      key: 'actions',
      render: () => {
        return (
          <Space>
            <DeleteFilled style={{ color: '#f56969' }} />
          </Space>
        );
      },
    });

    return columns;
  };

  return (
    <Card>
      <Card.Title title="Nota de fletes" />

      <Table<ShipmentNoteGlobal>
        actions={actions}
        columns={getColumns()}
        url={baseApiUrl}
      />
    </Card>
  );
};

export default List;
