import React from 'react';

import dayjs from 'dayjs';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import * as api from 'api';
import { Card, FormWrapper } from 'components';
import { useAccountUrl } from 'hooks';
import { Driver } from 'models';

import PageForm from './PageForm';

interface Props {
  onCancel?: () => void;
  onSuccess?: () => void;
}

const EditWrapper: React.FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;

  const { baseApiUrl } = useAccountUrl();
  const { driverId } = useParams<{ driverId: string }>();

  const { data: driver, isLoading } = useQuery(`getDriver__${driverId}`, () =>
    api.getDriver({
      meta: { baseUrl: baseApiUrl },
      params: { driverId: driverId },
    }),
  );
  const { mutateAsync } = useMutation(api.updateDriver);

  const handleFinish = async (values: Record<string, any>) => {
    if (!driver?.id) {
      return;
    }

    const data = values as Driver;
    (await mutateAsync({
      data: data,
      meta: { baseUrl: baseApiUrl },
      params: {
        driverId: driver.id,
      },
    })) && onSuccess?.();
  };

  return (
    <Card bodyStyle={{ margin: 'auto', width: '80%' }} loading={isLoading}>
      <FormWrapper
        initialValues={{
          ...driver,
          driverExpirationDate: dayjs(driver?.driverExpirationDate),
        }}
        layout="vertical"
        onFinish={handleFinish}
        onCancel={() => onCancel?.()}
      >
        <PageForm title="Editar operador" />
      </FormWrapper>
    </Card>
  );
};

export default EditWrapper;
