import React from 'react';

import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, useHistory } from 'react-router-dom';

import { Card, Table } from 'components';
import { useAccountUrl } from 'hooks';
import { Truck } from 'models';

const List = () => {
  const urls = useAccountUrl();
  const history = useHistory();

  const baseApiUrl = `${urls.baseApiUrl}/trucks`;
  const baseUrl = `${urls.baseUrl}/trucks`;

  const actions = [
    <Button
      icon={<PlusOutlined />}
      key="add"
      onClick={() => history.push(`${baseUrl}/add`)}
      type="primary"
    >
      Agregar unidad
    </Button>,
  ];

  const columns: ColumnsType<Truck> = [
    {
      dataIndex: ['name'],
      key: 'name',
      render: (text, record) => (
        <Link to={`${baseUrl}/${record.id}/edit`}>
          <span>{record.name}</span>
        </Link>
      ),
      title: 'Num. económico',
    },
    {
      dataIndex: ['plates'],
      key: 'plates',
      title: 'Placas',
    },
    {
      dataIndex: ['truckType', 'name'],
      key: 'truckTypeName',
      title: 'Tipo',
    },
    {
      dataIndex: ['truckStatus', 'name'],
      key: 'truckStatusName',
      title: 'Estatus',
    },
    {
      align: 'center',
      key: 'actions',
      render: (text, record) => {
        return (
          <Space>
            <DeleteFilled style={{ color: '#f56969' }} />
          </Space>
        );
      },
    },
  ];

  return (
    <Card>
      <Card.Title subTitle="Listado de unidades" title="Unidades" />

      <Table<Truck> actions={actions} columns={columns} url={baseApiUrl} />
    </Card>
  );
};

export default List;
