import React, { useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { useDebounceFn } from 'ahooks';
import { Form, Select, Spin } from 'antd';
import { FormItemProps } from 'antd/es/form';
import { SelectProps } from 'antd/es/select';
import { useQuery, UseQueryOptions } from 'react-query';

import { ID } from 'models';
import { fetch } from 'lib';

interface SelectSearchProps<TOption> {
  formItemProps: Partial<FormItemProps>;
  initialData?: TOption[];
  queryKey?: string;
  queryOptions?: UseQueryOptions<TOption[]>;
  queryParams?: Record<string, string>;
  renderOption: (option: TOption) => {};
  selectProps?: SelectProps<any>;
  url: string;
}

export function SelectSearch<TOption extends { id: ID }>(
  props: SelectSearchProps<TOption>,
) {
  const { formItemProps, initialData, queryKey, queryOptions } = props;
  const { queryParams, renderOption, selectProps, url } = props;

  const { run } = useDebounceFn(
    async () => {
      await refetch();
    },
    { wait: 750 },
  );
  const { data = [], isFetching, refetch } = useQuery(
    queryKey || `SELECT_SEARCH__${url}`,
    () => {
      if (!searchValue) {
        return Promise.resolve();
      }
      return fetch.call(
        'get',
        url,
        {},
        { params: { ...queryParams, search: searchValue } },
      );
    },
    queryOptions,
  );
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = async (value: string) => {
    setSearchValue(value);
    await run();
  };

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectProps}
        defaultActiveFirstOption={false}
        filterOption={false}
        notFoundContent={isFetching ? <Spin size="small" /> : null}
        onSearch={handleSearch}
        showSearch={true}
        suffixIcon={<SearchOutlined />}
      >
        {data &&
          data
            .concat(initialData || [])
            .reduce<TOption[]>((acc, curr) => {
              if (acc.find((a) => a.id === curr.id)) {
                return acc;
              }
              acc.push(curr);
              return acc;
            }, [])
            .map((option: TOption) => renderOption(option))}
      </Select>
    </Form.Item>
  );
}
