import { fetch } from 'lib';
import { ID, ShipmentNoteGlobal } from 'models';

interface Variables {
  meta: { baseUrl: string };
  params: { shipmentNoteId: ID };
}

export const getShipmentNote = ({ meta, params }: Variables) =>
  fetch.call<ShipmentNoteGlobal>(
    'GET',
    `${meta.baseUrl}/shipment-notes/${params.shipmentNoteId}`,
  );
