import React from 'react';

import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { TableList, UniqueCollapsable } from 'components';
import { Account, Route } from 'models';

interface Props {
  account: Account;
  baseUrl: string;
}

export const RouteList: React.FC<Props> = (props) => {
  const { account, baseUrl } = props;

  const columns: ColumnsType<Route> = [
    {
      key: 'customerOrder',
      render: (text, record) => {
        return (
          <Link to={`${baseUrl}/${account.id}/routes/${record.id}/detail`}>
            <span>{record.customerOrder}</span>
          </Link>
        );
      },
      title: 'Folio',
    },
  ];

  return (
    <UniqueCollapsable header="Fletes">
      <TableList<Route>
        columns={columns}
        historyPush={{
          state: { account },
          url: `${baseUrl}/${account.id}/routes`,
        }}
        url={`${baseUrl}/${account.id}/routes`}
      />
    </UniqueCollapsable>
  );
};

export default RouteList;
