import React from 'react';

import { Divider, Dropdown, Menu } from 'antd';
import { DropDownProps } from 'antd/es/dropdown/dropdown';

interface MenuOption {
  key: string;
  name: string;
  divider?: boolean;
}

type MenuOptions = MenuOption;
// type MenuOptions = MenuOption | React.ReactNode;

interface Props {
  children: React.ReactNode;
  dropdownProps?: Omit<DropDownProps, 'overlay'>;
  menuOptions: MenuOptions[];
  onMenuClick: (param: any) => void;
}

export const DropdownActions: React.FC<Props> = (props) => {
  const { children, dropdownProps, menuOptions, onMenuClick } = props;

  if (!menuOptions.length) {
    return null;
  }

  return (
    <Dropdown
      overlay={
        <Menu onClick={onMenuClick}>
          {menuOptions.map((item) => {
            if (item!!.hasOwnProperty('name')) {
              return (
                <>
                  <Menu.Item key={item.key}>{item.name}</Menu.Item>
                  {item.divider && (
                    <Divider style={{ margin: 0, padding: 0 }} />
                  )}
                </>
              );
            }
            return item;
          })}
        </Menu>
      }
      {...dropdownProps}
    >
      {children}
    </Dropdown>
  );
};
