import React from 'react';

import ProForm, { ProFormText } from '@ant-design/pro-form';
import { Button, Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';

import { UniqueCollapsable } from 'components';
import { Account } from 'models';

interface Props {
  account: Account;
  baseUrl: string;
}

const Information: React.FC<Props> = (props) => {
  const { account, baseUrl } = props;

  const history = useHistory();

  return (
    <UniqueCollapsable
      extra={
        <Button
          onClick={() => history.push(`${baseUrl}/${account.id}/edit`)}
          size="small"
        >
          Editar
        </Button>
      }
      header="Información de la cuenta"
    >
      <ProForm initialValues={account} layout="horizontal" submitter={false}>
        <Row gutter={24}>
          <Col span={8}>
            <ProFormText
              label="Nombre legal"
              name={['nameLegal']}
              readonly={true}
            />
          </Col>
          <Col span={8}>
            <ProFormText label="RFC" name={['rfc']} readonly={true} />
          </Col>
          <Col span={8}>
            <ProFormText
              label="Teléfono particular"
              name={['contact', 'phone']}
              readonly={true}
            />
          </Col>
          <Col span={8}>
            <ProFormText
              label="Nombre comercial"
              name={['name']}
              readonly={true}
            />
          </Col>
          <Col span={8}>
            <ProFormText
              initialValue={[
                account?.contact?.firstName,
                account?.contact?.lastName,
              ].join(',')}
              label="Representante"
              name={['fullName']}
              readonly={true}
            />
          </Col>
          <Col span={8}>
            <ProFormText
              label="Teléfono celular"
              name={['contact', 'phoneMobile']}
              readonly={true}
            />
          </Col>
          <Col span={8}>
            <ProFormText
              label="Usuario principal"
              name={['user']}
              readonly={true}
            />
          </Col>
          <Col span={8}>
            <ProFormText
              label="Email"
              name={['contact', 'email']}
              readonly={true}
            />
          </Col>
        </Row>
      </ProForm>
    </UniqueCollapsable>
  );
};

export default Information;
