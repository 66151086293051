import { fetch } from 'lib';
import { CarrierConfig, ID } from 'models';

interface Variables {
  data: CarrierConfig;
  params: { accountId: ID; carrierConfigId: ID };
}

export const updateCarrierConfig = ({ data, params }: Variables) =>
  fetch.call(
    'PUT',
    `/accounts/carriers/${params.accountId}/configs/${params.carrierConfigId}`,
    data,
  );
