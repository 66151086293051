import React, { useEffect } from 'react';

import { Button, Col, Form, Modal, Row, Select, Space } from 'antd';

import { SelectQuery } from 'components';
import { AccountContact, Truck } from 'models';

interface Props {
  onCancel: () => void;
  onFinish: (assigment: any) => void;
  route: any | null;
  visible: boolean;
}

const AssigmentStep: React.FC<Props> = (props) => {
  const { onCancel, onFinish, route, visible } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (!!route?.assignment) {
      form.setFieldsValue(route.assignment);
    } else {
      form.setFieldsValue({
        driver: undefined,
        trailer1: undefined,
        trailer2: undefined,
        truck: undefined,
      });
    }
  }, [form, route]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleFinish = () => {
    form.validateFields().then(() => {
      onFinish(form.getFieldsValue());
    });
  };

  const routeId = `${route?.carrier?.value || route?.carrier?.id}`;
  const baseCarrierUrl = `/accounts/carriers/${routeId}`;

  return (
    <Modal
      closable={false}
      destroyOnClose={true}
      footer={
        <Space>
          <Button onClick={handleCancel} type="default">
            Cancelar
          </Button>
          <Button onClick={handleFinish} type="primary">
            Guardar
          </Button>
        </Space>
      }
      title="Asignaciones para ruta"
      visible={visible}
    >
      <Form form={form} layout="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <SelectQuery<Truck>
              formItemProps={{
                label: 'ID de la unidad',
                rules: [{ required: true }],
                name: ['truck'],
              }}
              queryOptions={{
                enabled: !!routeId,
              }}
              renderOption={(option) => (
                <Select.Option key={option.name} value={option.id}>
                  {option.name}
                </Select.Option>
              )}
              selectProps={{
                disabled: !routeId,
                labelInValue: true,
              }}
              url={`${baseCarrierUrl}/trucks`}
            />
          </Col>

          <Col span={12}>
            <SelectQuery<AccountContact>
              formItemProps={{
                label: 'ID del operador',
                rules: [{ required: true }],
                name: ['driver'],
              }}
              queryOptions={{
                enabled: !!routeId,
              }}
              renderOption={(option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.firstName} {option.lastName}
                </Select.Option>
              )}
              selectProps={{
                disabled: !routeId,
                labelInValue: true,
              }}
              url={`${baseCarrierUrl}/drivers/available`}
            />
          </Col>

          <Col span={12}>
            <SelectQuery<Truck>
              formItemProps={{
                label: 'ID de la caja',
                rules: [{ required: true }],
                name: ['trailer1'],
              }}
              queryKey="account-carriers-trucks__trailer1"
              queryOptions={{
                enabled: !!routeId,
              }}
              queryParams={{
                isTowable: true,
              }}
              renderOption={(option) => (
                <Select.Option key={option.name} value={option.id}>
                  {option.name}
                </Select.Option>
              )}
              selectProps={{
                disabled: !routeId,
                labelInValue: true,
              }}
              url={`${baseCarrierUrl}/trucks`}
            />
          </Col>

          {route?.shipmentModalType?.value === 'full' && (
            <Col span={12}>
              <SelectQuery<Truck>
                formItemProps={{
                  label: 'ID de la caja (full)',
                  rules: [{ required: true }],
                  name: ['trailer2'],
                }}
                queryKey="account-carriers-trucks__trailer2"
                queryOptions={{
                  enabled: !!routeId,
                }}
                queryParams={{
                  isTowable: true,
                }}
                renderOption={(option) => (
                  <Select.Option key={option.name} value={option.id}>
                    {option.name}
                  </Select.Option>
                )}
                selectProps={{
                  disabled: !routeId,
                  labelInValue: true,
                }}
                url={`${baseCarrierUrl}/trucks`}
              />
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default AssigmentStep;
