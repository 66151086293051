import { fetch } from 'lib';

interface Variables {
  data: { routes: any[] };
}

export const addFastShipment = ({ data }: Variables) => {
  return fetch.call('POST', 'accounts/shipments/fast', {
    routes: data.routes.map((route) => {
      const { destination, origin, note, goods, weight } = route;
      const { shipmentModalType, shipmentType } = route;

      return {
        destination,
        extra: {
          etao: route.etao,
          etad: route.etad,
        },
        origin,
        receivers: route.receivers,
        routeCost: route.routeCost,
        routeSale: route.routeSale,
        shipmentModalTypeValue: shipmentModalType.value,
        shipmentTypeId: shipmentType.value,
        note,
        goods,
        weight,
      };
    }),
  });
};
