import { fetch } from '../../lib';
import { AccountLocation } from '../../models';

interface Variables {
  meta: { baseUrl: string };
  params: { accountLocationId: number | string };
}

export const getAccountLocation = ({ meta, params }: Variables) =>
  fetch.call<AccountLocation>(
    'GET',
    `${meta.baseUrl}/locations/${params.accountLocationId}`,
  );
