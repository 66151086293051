import React from 'react';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Layout, Row } from 'antd';
import { Redirect, useHistory } from 'react-router-dom';

import './Login.css';

import { useAuth } from '../../hooks';
import logo from '../../images/logo-white.svg';

export const Login = () => {
  const auth = useAuth();
  const history = useHistory();

  const handleFinish = (values: { email: string; password: string }) => {
    auth
      .login(values, () => {
        history.push('/dashboard');
      })
      .then(() => null);
  };

  if (auth.user) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Layout.Content className="login__content">
      <Row>
        <Col className="login__content-left" span={12}>
          <div className="content-left__logo">
            <img alt="logo" src={logo} />
          </div>
          <div className="content-left__welcome-container">
            <div>Bienvenido a el portal de Falcon Logics</div>
            <div className="welcome-container__footer">
              <p>Recomendación de Navegador</p>
              <p>
                Para usar correctamente el portal recomendamos: Google Chrome
                v89 o superior.
              </p>
            </div>
          </div>
        </Col>

        <Col className="login__content-right" span={12}>
          <div className="content-right__header">Ingresar credenciales</div>

          <div className="content-right__form">
            <Form onFinish={handleFinish}>
              <Form.Item name={['email']} rules={[{ required: true }]}>
                <Input placeholder="Usuario" prefix={<UserOutlined />} />
              </Form.Item>

              <Form.Item name={['password']} rules={[{ required: true }]}>
                <Input.Password
                  placeholder="Contraseña"
                  prefix={<LockOutlined />}
                />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit">Iniciar Sesión</Button>
              </Form.Item>
            </Form>
          </div>

          <div className="content-right__footer">
            <p>Soporte</p>
            <p>
              Si tiene algún problema con el acceso de su cuenta favor de enviar
              un correo a soporte@falconlogics.com
            </p>
          </div>
        </Col>
      </Row>
    </Layout.Content>
  );
};
