import React, { useState } from 'react';

import { Marker, useGoogleMap } from '@react-google-maps/api';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { Input } from 'antd';

import { Location } from 'models';

type SearchBox = google.maps.places.SearchBox | null;

interface MapSearchPlaceProps {
  onMarkerChange?: (lat: number, lng: number) => void;
  onPlaceChanged?: (
    data: Pick<Location, 'address' | 'geomLocation'> | null,
  ) => void;
  withMarker?: boolean;
}

const MapSearchPlace: React.FC<MapSearchPlaceProps> = (props) => {
  const { onMarkerChange, onPlaceChanged, withMarker = false } = props;

  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [searchBox, setSearchBox] = useState<SearchBox>(null);

  const map = useGoogleMap();

  const handlePlaceChanged = () => {
    if (!searchBox) {
      return;
    }

    const places = searchBox.getPlaces();

    if (!places.length) {
      return;
    }

    const place = places[0];
    const latLng = place.geometry?.location.toJSON();

    if (latLng && map) {
      map.setCenter(latLng);
      map.setZoom(18);

      withMarker && setCoordinates({ lat: latLng.lat, lng: latLng.lng });

      onPlaceChanged &&
        onPlaceChanged({
          address: place.formatted_address || '',
          geomLocation: {
            lat: latLng.lat,
            lng: latLng.lng,
          },
        });
    }
  };

  return (
    <React.Fragment>
      {withMarker && !!coordinates && (
        <Marker
          draggable={true}
          onDragEnd={(event) => {
            const latLng = event.latLng.toJSON();
            setCoordinates({ lat: latLng.lat, lng: latLng.lng });
            onMarkerChange && onMarkerChange(latLng.lat, latLng.lng);
          }}
          position={coordinates}
        />
      )}
      <div style={{ margin: 10, width: '100%' }}>
        <StandaloneSearchBox
          onPlacesChanged={handlePlaceChanged}
          onLoad={(ref) => setSearchBox(ref)}
        >
          <Input />
        </StandaloneSearchBox>
      </div>
    </React.Fragment>
  );
};

export default MapSearchPlace;
