import React, { useState } from 'react';

import { ArrowRightOutlined, SnippetsTwoTone } from '@ant-design/icons';
import { ProFormText, ProFormDigit } from '@ant-design/pro-form';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { Dayjs } from 'dayjs';

import { Card, DatePicker, SelectQuery } from 'components';
import { Account, AccountLocation, ShipmentModalType } from 'models';
import { ShipmentType } from 'models';
import TextArea from 'antd/lib/input/TextArea';

interface Props {
  editableRouteIndex: number | undefined;
  onCancel: () => void;
  onFinish: (routes: any[]) => void;
  routes: any[];
  routeType: 'multiple' | 'single' | 'fast';
  isWaiver: boolean;
}

const RouteForm: React.FC<Props> = (props) => {
  const {
    editableRouteIndex,
    onCancel,
    onFinish,
    routes,
    routeType,
    isWaiver,
  } = props;

  const [form] = Form.useForm();
  const [routesCached, setRoutesCached] = useState<any[]>(routes);
  const [index, setIndex] = useState(editableRouteIndex || 0);
  const generalInfoSpan = routeType === 'fast' ? 6 : isWaiver ? 12 : 8;

  const handleFinish = (exit = true) => {
    form
      .validateFields()
      .then(() => {
        if (exit) {
          if (routeType === 'single' || routeType === 'fast') {
            const fields = form.getFieldsValue().routes;
            onFinish(fields);
          }

          if (routeType === 'multiple') {
            if (editableRouteIndex === undefined) {
              onFinish([...routesCached, form.getFieldsValue().routes[index]]);
            } else {
              const tmpRoutes = [...routesCached];
              tmpRoutes[index] = form.getFieldsValue().routes[index];
              onFinish(tmpRoutes);
            }
          }
        } else {
          const tmpRoutes = [...routesCached];
          tmpRoutes[index] = form.getFieldsValue().routes[index];
          setRoutesCached(tmpRoutes);
          setIndex(index + 1);
        }
      })
      .catch(() => null);
  };

  return (
    <Form
      initialValues={{ routes: routes }}
      form={form}
      layout="vertical"
      preserve={true}
    >
      <Row gutter={24}>
        {routeType === 'multiple' && (
          <Card.Title title={`Crear tramo ${index + 1}`} />
        )}

        {routeType === 'single' && <Card.Title title="Crear viaje" />}

        {routeType === 'fast' && (
          <Card.Title title="Creación de viaje rapido" />
        )}

        <Card.Divider>Información general</Card.Divider>

        {routeType !== 'fast' && (
          <Col span={generalInfoSpan}>
            <SelectQuery<Account>
              formItemProps={{
                label: 'Seleccionar cliente',
                rules: [{ required: true }],
                name: ['routes', index, 'client'],
              }}
              renderOption={(option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              )}
              selectProps={{
                labelInValue: true,
              }}
              url="/accounts/clients"
            />
          </Col>
        )}

        {routeType !== 'fast' && (
          <Col span={generalInfoSpan}>
            <ProFormText
              label="No. de orden/folio"
              name={['routes', index, 'customerOrder']}
              placeholder=""
              rules={[{ required: true }]}
            />
          </Col>
        )}

        {routeType !== 'fast' && !isWaiver && <Col span={8} />}

        <Col span={generalInfoSpan}>
          <SelectQuery<ShipmentType>
            formItemProps={{
              label: 'Relación de tipo',
              rules: [{ required: true }],
              name: ['routes', index, 'shipmentType'],
            }}
            renderOption={(option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            )}
            selectProps={{
              labelInValue: true,
            }}
            url="/accounts/shipments-types"
          />
        </Col>

        {routeType !== 'fast' && !isWaiver && (
          <Col span={generalInfoSpan}>
            <Form.Item
              noStyle={true}
              shouldUpdate={(prevValues, nextValues) =>
                prevValues?.routes?.[index]?.shipmentType?.value !==
                nextValues?.routes?.[index]?.shipmentType?.value
              }
            >
              {({ getFieldValue }) => {
                const shipmentType = getFieldValue([
                  'routes',
                  index,
                  'shipmentType',
                ]);
                return (
                  <SelectQuery<Account>
                    formItemProps={{
                      label: 'Transportista',
                      rules: [{ required: true }],
                      name: ['routes', index, 'carrier'],
                    }}
                    queryOptions={{
                      enabled: !!shipmentType?.key,
                    }}
                    renderOption={(option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    )}
                    selectProps={{
                      disabled: !shipmentType?.key,
                      labelInValue: true,
                    }}
                    url={`/accounts/carriers/shipment-types/${shipmentType?.key}`}
                  />
                );
              }}
            </Form.Item>
          </Col>
        )}

        <Col span={generalInfoSpan}>
          <SelectQuery<ShipmentModalType>
            formItemProps={{
              label: 'Tipo de viaje',
              rules: [{ required: true }],
              name: ['routes', index, 'shipmentModalType'],
            }}
            renderOption={(option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.name}
              </Select.Option>
            )}
            selectProps={{
              labelInValue: true,
            }}
            url="/catalogs/shipment-modal-type"
          />
        </Col>

        <Col span={24}>
          <Form.Item
            label="Carga"
            name={['routes', index, 'goods']}
            rules={[{ required: true }]}
          >
            <TextArea autoSize={{ minRows: 4, maxRows: 6 }} />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            label="Peso"
            name={['routes', index, 'weight']}
            rules={[{ required: true }]}
          >
            <Input type="number" suffix="KG" />
          </Form.Item>
        </Col>

        <Card.Divider>Ruta</Card.Divider>

        {routeType !== 'fast' ? (
          <Col span={10}>
            <Form.Item
              noStyle={true}
              shouldUpdate={(prevValues, nextValues) =>
                prevValues?.routes?.[index]?.client?.value !==
                nextValues?.routes?.[index]?.client?.value
              }
            >
              {({ getFieldValue }) => {
                const client = getFieldValue(['routes', index, 'client']);
                return (
                  <SelectQuery<AccountLocation>
                    formItemProps={{
                      label: 'Origen',
                      rules: [{ required: true }],
                      name: ['routes', index, 'origin'],
                    }}
                    queryOptions={{
                      enabled: !!client?.value,
                    }}
                    queryParams={{
                      withOwner: true,
                    }}
                    renderOption={(option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    )}
                    selectProps={{
                      disabled: !client?.value,
                      labelInValue: true,
                    }}
                    url={`/accounts/clients/${client?.value}/locations`}
                  />
                );
              }}
            </Form.Item>
          </Col>
        ) : (
          <Col span={11}>
            <Form.Item
              name={['routes', index, 'origin', 'address']}
              label={<p style={{ margin: '0px' }}>Origen</p>}
              rules={[{ required: true }]}
            >
              <Input
                disabled
                style={{ padding: '0px' }}
                placeholder="Copia y pega un enlace de google maps"
                prefix={
                  <div
                    style={{
                      width: '35px',
                      height: '100%',
                      margin: '0px',
                      color: 'white',
                      fontWeight: 'bold',
                      background: '#8ABB00',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '4px 11px',
                      marginRight: '5px',
                      borderRadius: '2px 0 0 2px',
                    }}
                  >
                    A
                  </div>
                }
                suffix={
                  <Tooltip title="Pegar desde portapapeles">
                    <SnippetsTwoTone
                      style={{ padding: '4px 11px' }}
                      onClick={async () => {
                        const content = decodeURIComponent(
                          await navigator.clipboard.readText(),
                        );

                        const google = content.match(
                          /https:\/\/www.google.com/gm,
                        );
                        const maps = content.match(/maps/gm);

                        if (google && maps) {
                          const address = content
                            .match(/(?<=place\/)(.*?)(?=\/)/gm)!![0]
                            .replace(/\+/gm, ' ');

                          const lat = content.match(/(?<=@)(.*?)(?=,)/gm)!![0];
                          const lng = content.match(
                            /(?<=,)-?[0-9]+.[0-9]+/gm,
                          )!![0];
                          form.setFields([
                            {
                              name: ['routes', index, 'origin', 'address'],
                              value: address,
                            },
                            {
                              name: [
                                'routes',
                                index,
                                'origin',
                                'coordinates',
                                'lat',
                              ],
                              value: lat,
                            },
                            {
                              name: [
                                'routes',
                                index,
                                'origin',
                                'coordinates',
                                'lng',
                              ],
                              value: lng,
                            },
                          ]);
                        } else {
                          //error msg
                          notification['error']({
                            message: 'Enlace incorrecto',
                            description: `Intente buscar la direccion deseada en google maps y pegar el enlace completo, Contenido del portapapeles : ${content}`,
                          });
                        }
                      }}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>

            <Row justify="space-between">
              <Col>
                <Form.Item
                  name={['routes', index, 'origin', 'coordinates', 'lat']}
                  label="Latitud"
                  rules={[{ required: true }]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name={['routes', index, 'origin', 'coordinates', 'lng']}
                  label="Longitud"
                  rules={[{ required: true }]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}

        <Col span={2}>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <ArrowRightOutlined style={{ fontSize: 24 }} />
          </div>
        </Col>

        {routeType !== 'fast' ? (
          <Col span={10}>
            <Form.Item
              noStyle={true}
              shouldUpdate={(prevValues, nextValues) =>
                prevValues?.routes?.[index]?.client?.value !==
                nextValues?.routes?.[index]?.client?.value
              }
            >
              {({ getFieldValue }) => {
                const client = getFieldValue(['routes', index, 'client']);
                return (
                  <SelectQuery<AccountLocation>
                    formItemProps={{
                      label: 'Destino',
                      rules: [{ required: true }],
                      name: ['routes', index, 'destination'],
                    }}
                    queryOptions={{
                      enabled: !!client?.value,
                    }}
                    renderOption={(option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    )}
                    selectProps={{
                      disabled: !client?.value,
                      labelInValue: true,
                    }}
                    url={`/accounts/clients/${client?.value}/locations`}
                  />
                );
              }}
            </Form.Item>
          </Col>
        ) : (
          <Col span={11}>
            <Form.Item
              name={['routes', index, 'destination', 'address']}
              label={<p style={{ margin: '0px' }}>Destino</p>}
              rules={[{ required: true }]}
            >
              <Input
                disabled
                style={{ padding: '0px' }}
                placeholder="Copia y pega un enlace de google maps"
                prefix={
                  <div
                    style={{
                      width: '35px',
                      height: '100%',
                      margin: '0px',
                      color: 'white',
                      fontWeight: 'bold',
                      background: '#EA371C',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '4px 11px',
                      marginRight: '5px',
                      borderRadius: '2px 0 0 2px',
                    }}
                  >
                    B
                  </div>
                }
                suffix={
                  <Tooltip title="Pegar desde portapapeles">
                    <SnippetsTwoTone
                      style={{ padding: '4px 11px' }}
                      onClick={async () => {
                        const content = decodeURIComponent(
                          await navigator.clipboard.readText(),
                        );
                        const google = content.match(
                          /https:\/\/www.google.com/gm,
                        );
                        const maps = content.match(/maps/gm);

                        if (google && maps) {
                          const address = content
                            .match(/(?<=place\/)(.*?)(?=\/)/gm)!![0]
                            .replace(/\+/gm, ' ');
                          const lat = content.match(/(?<=@)(.*?)(?=,)/gm)!![0];
                          const lng = content.match(
                            /(?<=,)-?[0-9]+.[0-9]+/gm,
                          )!![0];
                          form.setFields([
                            {
                              name: ['routes', index, 'destination', 'address'],
                              value: address,
                            },
                            {
                              name: [
                                'routes',
                                index,
                                'destination',
                                'coordinates',
                                'lat',
                              ],
                              value: lat,
                            },
                            {
                              name: [
                                'routes',
                                index,
                                'destination',
                                'coordinates',
                                'lng',
                              ],
                              value: lng,
                            },
                          ]);
                        } else {
                          //error msg
                          notification['error']({
                            message: 'Enlace incorrecto',
                            description: `Intente buscar la direccion deseada en google maps y pegar el enlace completo, Contenido del portapapeles : ${content}`,
                          });
                        }
                      }}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Row justify="space-between">
              <Col>
                <Form.Item
                  name={['routes', index, 'destination', 'coordinates', 'lat']}
                  label="Latitud"
                  rules={[{ required: true }]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name={['routes', index, 'destination', 'coordinates', 'lng']}
                  label="Longitud"
                  rules={[{ required: true }]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}
        <Card.Divider>Costos</Card.Divider>

        <Col span={8}>
          <ProFormDigit
            label="Costo del flete"
            max={1000000}
            min={0}
            name={['routes', index, 'routeCost']}
            placeholder=""
            rules={[{ required: true }]}
          />
        </Col>

        {!isWaiver && (
          <Col span={8}>
            <ProFormDigit
              label="Monto de la venta del flete"
              max={1000000}
              min={0}
              name={['routes', index, 'routeSale']}
              placeholder=""
              rules={[{ required: true }]}
            />
          </Col>
        )}

        <Card.Divider>Tiempos estimados</Card.Divider>

        <Col span={6}>
          <Form.Item
            label={
              <Tooltip title="Tiempo estimado de llegada al origen">
                <span>ETAO</span>
              </Tooltip>
            }
            name={['routes', index, 'etao']}
            rules={[{ required: true }]}
          >
            <DatePicker showTime={true} />
          </Form.Item>
        </Col>

        {routeType !== 'fast' && (
          <Col span={6}>
            <Form.Item
              noStyle={true}
              shouldUpdate={(prevValues, nextValues) =>
                prevValues?.routes?.[index]?.etao !==
                nextValues?.routes?.[index]?.etao
              }
            >
              {({ getFieldValue }) => {
                const etao: null | Dayjs = getFieldValue([
                  'routes',
                  index,
                  'etao',
                ]);

                return (
                  <Form.Item
                    label={
                      <Tooltip title="Tiempo estimado de salida del origen">
                        <span>ETDO</span>
                      </Tooltip>
                    }
                    name={['routes', index, 'etdo']}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      disabled={!etao}
                      disabledDate={(date) => !!date && !!etao && date < etao}
                      showTime={true}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}

        <Col span={6}>
          <Form.Item
            label={
              <Tooltip title="Tiempo estimado de llegada al destino">
                <span>ETAD</span>
              </Tooltip>
            }
            name={['routes', index, 'etad']}
            rules={[{ required: true }]}
          >
            <DatePicker showTime={true} />
          </Form.Item>
        </Col>
        {routeType !== 'fast' && (
          <Col span={6}>
            <Form.Item
              label={
                <Tooltip title="Tiempo estimado de salida del destino">
                  <span>ETDD</span>
                </Tooltip>
              }
              name={['routes', index, 'etdd']}
              rules={[{ required: true }]}
            >
              <DatePicker showTime={true} />
            </Form.Item>
          </Col>
        )}

        <Card.Divider>Nota</Card.Divider>
        <Col span={24}>
          <Form.Item name={['routes', index, 'note']}>
            <TextArea autoSize={{ minRows: 4, maxRows: 6 }} />
          </Form.Item>
        </Col>
        <Divider />

        <Col span={12}>
          {routeType === 'multiple' &&
            index > 0 &&
            editableRouteIndex === undefined && (
              <Button onClick={() => setIndex(index - 1)}>Regresar</Button>
            )}
        </Col>

        <Col span={12} style={{ textAlign: 'right' }}>
          <Space>
            {(routeType === 'single' || routeType === 'fast') && (
              <React.Fragment>
                <Button onClick={() => onCancel()}>Regresar</Button>
                <Button onClick={() => handleFinish()} type="primary">
                  Siguiente
                </Button>
              </React.Fragment>
            )}

            {routeType === 'multiple' &&
              index === 0 &&
              editableRouteIndex === undefined && (
                <React.Fragment>
                  <Button onClick={() => onCancel()}>Cancelar</Button>
                  <Button onClick={() => handleFinish(false)} type="primary">
                    Siguiente
                  </Button>
                </React.Fragment>
              )}

            {routeType === 'multiple' &&
              index > 0 &&
              editableRouteIndex === undefined && (
                <React.Fragment>
                  <Button onClick={() => handleFinish(false)}>
                    Agregar otro tramo
                  </Button>
                  <Button onClick={() => handleFinish()} type="primary">
                    Finalizar
                  </Button>
                </React.Fragment>
              )}

            {routeType === 'multiple' && editableRouteIndex !== undefined && (
              <Button onClick={() => handleFinish()} type="primary">
                Finalizar
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default RouteForm;
