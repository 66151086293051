import React from 'react';

import { ProFormText } from '@ant-design/pro-form';
import { Col, Row } from 'antd';

import { Card } from 'components';

interface Props {
  readonly?: boolean;
  title?: string;
}

const PageForm: React.FC<Props> = (props) => {
  const { readonly = false, title } = props;

  return (
    <Row gutter={24}>
      {title && <Card.Title title={title} />}

      <Card.Divider>Información general</Card.Divider>

      <Col span={8}>
        <ProFormText
          label="Nombre comercial"
          name={['name']}
          rules={[{ required: true }]}
          readonly={readonly}
        />
      </Col>

      <Col span={8}>
        <ProFormText
          label="Nombre legal"
          name={['nameLegal']}
          readonly={readonly}
          rules={[{ required: true }]}
        />
      </Col>

      <Col span={8} />

      <Col span={8}>
        <ProFormText
          label="Nombre(s) del representante"
          name={['contact', 'firstName']}
          readonly={readonly}
          rules={[{ required: true }]}
        />
      </Col>

      <Col span={8}>
        <ProFormText
          label="Apellido(s) del representante"
          name={['contact', 'lastName']}
          readonly={readonly}
          rules={[{ required: true }]}
        />
      </Col>

      <Col span={8} />

      <Col span={8}>
        <ProFormText
          label="RFC"
          name={['rfc']}
          readonly={readonly}
          rules={[{ required: true }]}
        />
      </Col>

      <Col span={8}>
        <ProFormText
          label="Email"
          name={['contact', 'email']}
          readonly={readonly}
          rules={[{ type: 'email' }, { required: true }]}
        />
      </Col>

      <Col span={8} />

      <Col span={8}>
        <ProFormText
          label="Teléfono"
          name={['contact', 'phone']}
          readonly={readonly}
          rules={[{ required: true }]}
        />
      </Col>

      <Col span={8}>
        <ProFormText
          label="Celular"
          name={['contact', 'phoneMobile']}
          readonly={readonly}
          rules={[{ required: true }]}
        />
      </Col>
    </Row>
  );
};

export default PageForm;
